<template>
  <!-- ALL WAREHOUSE IS NOW MVP -->
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-6">
        <h4>Создать поставку</h4>
      </div>
      <div
        class="text-right col-lg-6 d-flex flex-column justify-content-center"
      ></div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4">
        <div class="card position-sticky top-1">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item pt-2">
              <a
                class="nav-link text-dark d-flex"
                data-scroll=""
                href="#basic-info"
              >
                <i class="material-icons text-lg me-2">receipt_long</i>
                <span class="text-sm">Общая информация</span>
              </a>
            </li>
            <div class="w-50 mt-3">
              <a class="btn btn-primary" href="" role="button">Сохранить</a>
            </div>
          </ul>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Общая информация</h5>
            <div class="row">
              <div class="col-sm-6">
                <label class="mt-3 ms-0">Поставщик</label>
                <!-- Нужен компонент, который позволит быстро искать из дропдауна нужный поставщика если их будет много -->
                <select
                  class="form-control"
                  name="choices-color"
                  id="choices-color-edit"
                >
                  <option value="Choice 1" selected>Loreal</option>
                  <option value="Choice 2">P&G</option>
                  <option value="Choice 3">Поставщик 3</option>
                  <option value="Choice 4">Поставщик 4</option>
                  <option value="Choice 5">Поставщик 5</option>
                </select>
              </div>
              <div class="col-sm-6">
                <label class="mt-3 ms-0">Товар</label>
                <!-- Нужен компонент, который позволит быстро искать из дропдауна нужный товар если их будет много -->
                <select
                  class="form-control"
                  name="choices-category"
                  id="choices-category-edit"
                >
                  <option value="Choice 1" selected>Товар 1</option>
                  <option value="Choice 5">Товар 2</option>
                  <option value="Choice 5">Товар 3</option>
                  <option value="Choice 5">Товар 4</option>
                  <option value="Choice 2">Товар 5</option>
                  <option value="Choice 4">Товар 6</option>
                  <option value="Choice 5">Товар 7</option>
                  <option value="Choice 5">Товар 8</option>
                  <option value="Choice 5">Товар 9</option>
                  <option value="Choice 5">Товар 10</option>
                  <option value="Choice 5">Товар 11</option>
                </select>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-3">
                <VmdInput variant="dynamic" label="Количество" />
                <span> шт </span>
              </div>
              <div class="col-3">
                <VmdInput variant="dynamic" label="Цена поставки" /> за
                <span> шт </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4"></div>
              <div class="col-4 text-center">
                <button
                  class="btn btn-sm btn-outline-dark px-3 mb-0"
                  type="button"
                  id="dropdownImport"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Добавить
                </button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4"></div>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <span class="mb-2 text-lg"> Сумма: </span>
              <span class="text-dark text-lg ms-2 font-weight-bold">750</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-sm-4">
        <div class="card"></div>
      </div>
      <div class="mt-3 col-sm-8 mt-sm-0">
        <div class="row">
          <div class="col-lg-6"></div>
          <div
            class="text-right col-lg-6 d-flex flex-column justify-content-center"
          >
            <button
              type="button"
              class="mt-2 mb-0 btn bg-gradient-primary ms-lg-auto me-lg-0 me-auto mt-lg-0"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
import Choices from "choices.js";
import VmdInput from "@/components/VmdInput.vue";

export default {
  name: "edit-product",

  components: { VmdInput },
  mounted() {
    this.getChoices("choices-category-edit");
    this.getChoices("choices-color-edit");
    this.getChoices("choices-currency-edit");

    if (document.getElementById("choices-tags-edit")) {
      var tags = document.getElementById("choices-tags-edit");
      const examples = new Choices(tags, {
        removeItemButton: true,
      });

      examples.setChoices(
        [
          {
            value: "One",
            label: "Expired",
            disabled: true,
          },
          {
            value: "Two",
            label: "Out of Stock",
            selected: true,
          },
        ],
        "value",
        "label",
        false,
      );
    }
  },
  methods: {
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
        });
      }
    },
  },
};
</script>
