<template>
  <div class="container-fluid" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mt-3">
      <div class="col-12">
        <router-link
          to="/settings/positions/new"
          class="btn btn-primary"
          role="button"
        >
          {{ getLabelAddPosition() }}
        </router-link>

        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h6 class="mb-0">{{ getLabelAllPositions() }}</h6>
          </div>

          <div class="vgt-responsive">
            <table class="custom-table vgt-table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  ></th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 thead th"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>Назва</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>Опис</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>ДОСТУПНІСТЬ</span>
                  </th>
                </tr>
              </thead>

              <draggable
                @end="onEnd"
                v-model="tableData.rows"
                v-bind="dragOptions"
                handle=".handle"
                tag="tbody"
                item-key="name"
              >
                <template #item="{ element }">
                  <tr @click="routeToItem(element._id)" class="">
                    <td>
                      <div class="text-center disableCopyElement">
                        <i class="material-icons text-lg me-2 grab-box handle">
                          drag_indicator</i
                        >
                      </div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <span>{{ element.name }}</span>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <span>{{
                        truncateString(element?.description || "", 120)
                      }}</span>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <div class="mx-1">
                        <span
                          class="text-xxs badge bg-gradient-success"
                          v-if="element.isActive"
                          >акивний</span
                        >
                        <span
                          class="text-xxs badge bg-gradient-danger"
                          v-if="!element.isActive"
                          >не активний</span
                        >
                      </div>
                    </td>
                  </tr>
                </template>
              </draggable>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/views/shared/Spinner.vue";

import api from "../../../../services/api";
import constants from "@/constants";
import { useTable } from "@/composables/good-table";
import draggable from "vuedraggable";

export default {
  setup() {
    const {
      onColumnFilter,
      onPageChange,
      onPerPageChange,
      onSortChange,
      loadItems,
      onRowClick,
      tableData,
      tablePerPage,
      paginationOptions,
      sortOptions,
      isLoading,
    } = useTable(api.positions.list);

    return {
      onColumnFilter,
      onPageChange,
      onPerPageChange,
      onSortChange,
      loadItems,
      onRowClick,
      paginationOptions,
      sortOptions,
      isLoading,
      tableData,
      tablePerPage,
    };
  },
  name: "settings-positions",

  components: {
    draggable,
    Spinner,
  },

  async mounted() {
    this.company = await api.company.show();

    setTimeout(() => {
      this.dataLoaded = true;
      this.loadItems();
    }, 500);
  },

  data() {
    return {
      dataLoaded: false,

      company: {
        crmMode: "",
      },

      datatableColumns: [
        {
          label: "",
          field: "correct",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Назва",
          field: "name",
          sortable: false,

          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },

        {
          label: "Доступність",
          field: "status",
          sortable: false,
          type: "boolean",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start",
          tdClass: "text-xs font-weight-normal text-start",
        },
      ],
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 500,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  methods: {
    getLabelAddPosition() {
      if (this.company.crmMode === "hotel") {
        return "+ категорія";
      }
      if (this.company.crmMode === "general") {
        return "+ категорія";
      }

      return "+ категорія";
    },
    getLabelAllPositions() {
      if (this.company.crmMode === "hotel") {
        return "Всі категорії";
      }
      if (this.company.crmMode === "general") {
        return "Всі категорії";
      }

      return "Всі категорії";
    },

    async remove(id) {
      await api.positions.removeOne(id);
    },

    routeToItem(id) {
      this.$router.push(`/settings/positions/${id}`);
    },

    async onEnd() {
      let sortIndex = 0;
      const newOrder = this.tableData.rows.map((item) => {
        sortIndex += 1;
        return {
          _id: item._id,
          rank: sortIndex,
        };
      });

      try {
        await api.positions.sort(newOrder);
        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    truncateString(input, maxLength) {
      if (!input) {
        return input;
      }
      if (input.length < maxLength) {
        return input;
      }
      return `${input.substring(0, maxLength)}...`;
    },
  },
};
</script>
<style scoped>
.container-fluid {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
</style>
