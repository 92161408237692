<script setup>
import Multiselect from "vue-multiselect";
import { computed, ref, useSlots } from "vue";

const propsDefault = {
  closeOnSelect: true,
  searchable: false,
  selectLabel: "",
  deselectGroupLabel: "",
  deselectLabel: "",
  selectGroupLabel: "",
  tagPlaceholder: "",
  selectedLabel: "",
  showLabels: false,
};

const props = defineProps({
  settings: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: [Array, Object, null, String, Number],
    required: true,
  },
});
const emit = defineEmits([
  "update:modelValue",
  "select",
  "searchChange",
  "remove",
]);

const slots = useSlots();

const model = computed({
  set(value) {
    emit("update:modelValue", value);
  },
  get() {
    return props.modelValue;
  },
});

const placeholder = computed(() => {
  if (model.value?.length === 0 && isOpened.value) {
    return "Нічого не обрано";
  }

  return props.settings?.placeholder;
});

const allProps = computed(() => {
  return { ...propsDefault, ...props.settings };
});

const isGroup = computed(() => {
  return props.settings?.groupSelect ?? false;
});

const isMultiple = computed(() => {
  return props.settings?.multiple;
});

const isOpened = ref(false);

function close() {
  isOpened.value = false;
}
function open() {
  isOpened.value = true;
}
</script>

<template>
  <multiselect
    v-bind="allProps"
    v-model="model"
    :placeholder="placeholder"
    @close="close"
    @open="open"
    @searchChange="(query) => emit('searchChange', query)"
    @select="(option, id) => emit('select', option, id)"
    @update:modelValue="(items) => emit('update:modelValue', items)"
    @remove="
      (selectedOrRemovedOption, id) =>
        emit('remove', selectedOrRemovedOption, id)
    "
  >
    <template #noResult> Нічого не знайдено</template>
    <template #noOptions>
      <div class="text-center">Нічого не знайдено</div>
    </template>
    <template #option="props">
      <div
        class="d-flex align-items-center gap"
        :class="{ nested: !props.option.$isLabel && isGroup }"
      >
        <span v-if="isMultiple" class="check"> </span>
        <template v-if="typeof props.option !== 'object'">
          {{ props.option }}
        </template>
        <template v-else>
          {{
            props.option.name || props.option.label || props.option.$groupLabel
          }}
        </template>
      </div>
    </template>

    <template v-for="(_, slot) in slots" :key="slot" #[slot]="slotProps">
      <slot :name="slot" v-bind="slotProps" />
    </template>
  </multiselect>
</template>

<style scoped>
.multiselect {
  --line-color: #d9d9d9;
  --descriptions: #8d969c;
  --forest700: #285441;
  --forest500: #458466;
  --forest300: #94c1a9;
  --forest200: #bfdbca;
  --forest50: #f1f8f4;
  --Selector-color: #f6f6f6;

  cursor: pointer;
}
:deep(.multiselect-container) {
  border-radius: 8px;
  border: 1px solid var(--line-color);
  background: #fff;
}
:deep(.multiselect__tags) {
  padding: 8.5px 50px 8.5px 10px;
  display: flex;
  min-height: 46px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

:deep(.multiselect__placeholder) {
  color: var(--descriptions);
  font-size: 16px;
  margin-bottom: 0;
}
:deep(.multiselect__select) {
  color: var(--descriptions);
  width: 24px;
  padding: 0;
  height: 25px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: url("~@/assets/img/HiChevronDown.svg") center / cover no-repeat;
}
:deep(.multiselect__select::before) {
  display: none;
}
.multiselect--active:deep(.multiselect__select) {
  transform: translate(0, -50%) rotateZ(180deg);
}

:deep(.multiselect__tags-wrap) {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
}
:deep(.multiselect__tag) {
  border-radius: 4px;
  background: var(--forest700);
  font-size: 15px;
  padding: 6px 10px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  margin: 0;
}
:deep(.multiselect__tag-icon) {
  position: relative;
  width: 14px;
  height: 14px;
  margin-left: 0;
  background: url("~@/assets/img/closeTag.svg") center / cover no-repeat;
}
:deep(.multiselect__tag-icon::after) {
  display: none;
}
:deep(.multiselect__option),
:deep(.multiselect__option--group.multiselect__option) {
  min-height: 42px;
  padding: 10px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  color: rgba(30, 30, 30, 1);
  background: transparent;
}

:deep(.multiselect__option--highlight.multiselect__option) {
  background: var(--forest50, #bfdbca);
}
:deep(.multiselect__option--selected.multiselect__option) {
  background: var(--forest200, #bfdbca);
}
:deep(.multiselect__option.multiselect__option--group:hover) {
  background: var(--forest50, #bfdbca);
}
:deep(.multiselect__option:hover) {
  background: var(--forest50, #bfdbca);
}
:deep(.multiselect__option--group-selected.multiselect__option) {
  background: var(--forest300);
  color: rgba(30, 30, 30, 1);
  font-weight: 400;
}
:deep(.multiselect__option--selected.multiselect__option .check),
:deep(.multiselect__option--group-selected.multiselect__option .check) {
  background:
    url("~@/assets/img/check.svg") center / 11px 10px no-repeat,
    var(--forest500);
  border-color: var(--forest500);
}
:deep(.check) {
  border-radius: 2px;
  border: 1px solid var(--line-color, #d9d9d9);
  background: var(--Selector-color, #f6f6f6);
  flex-shrink: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
}
.input-wrapper {
  padding: 20px 10px 10px;
}
:deep(.multiselect__input) {
  padding: 0 18px;
  opacity: 0;
  border-radius: 8px !important;
  font-size: 16px !important;
  border: 1px solid #dadadc;
  background: var(--Selector-color, #f6f6f6);
  height: 42px;
  color: rgba(30, 30, 30, 1);
  display: block;
  margin-bottom: 0;
}
:deep(.multiselect__input::placeholder) {
  font-size: 16px !important;
  color: var(--descriptions);
}
.multiselect--active:deep(.multiselect__input) {
  opacity: 1;
}
:deep(.nested) {
  padding-left: 30px;
}
:deep(.gap) {
  gap: 10px;
}
:deep(.multiselect__content-wrapper) {
  padding-top: 8px;
}
.multiselect.multiselect--active:deep(.multiselect__placeholder) {
  display: block;
}
:deep(.multiselect__single) {
  padding-left: 0;
  margin-bottom: 0;
}
:deep(.multiselect__spinner) {
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  right: 10px;
}
</style>
