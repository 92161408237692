<script setup>
import { v4 as uuidv4 } from "uuid";
import { ref, watch, computed } from "vue";

const props = defineProps({
  colors: {
    type: Array,
    required: true,
  },
  withEmptyColor: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const colors = ref([]);

watch(
  () => props.colors,
  (val) => {
    if (val) {
      if (props.withEmptyColor) {
        colors.value.push({ id: uuidv4(), color: "" });
      }
      for (const color of val) {
        colors.value.push({ id: uuidv4(), color: color });
      }
    }
  },
  { immediate: true },
);
</script>
<template>
  <div class="color-radio">
    <div class="color-radio__items">
      <div v-for="item of colors" :key="item.id" class="color-radio__item">
        <label class="color-radio__label">
          <input
            v-model="value"
            class="color-radio__input"
            name="color-radio"
            type="radio"
            :value="item.color"
          />
          <span
            :style="{ backgroundColor: item.color }"
            class="color-radio__circle"
            :class="{ 'without-color': !item.color }"
          ></span>
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.color-radio {
  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__item {
  }

  &__circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-flex;
    margin-bottom: 0;
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
    cursor: pointer;
    &.without-color {
      position: relative;
      border: 1px solid lightgray;
      &::after {
        content: "";
        position: absolute;
        width: 180%;
        height: 2px;
        background: lightgray;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  &__input {
    position: absolute;
    z-index: -1;
    visibility: hidden;
    width: 0;
    &:checked + .color-radio__circle {
      box-shadow:
        0px 0px 0px 2px #fff,
        0px 0px 0px 4px #4caf50;
    }
  }
}
</style>
