<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mb-5">
      <div class="col-lg-9 mx-auto">
        <div class="card mb-2" id="additional-settings" v-if="showDevFeatures">
          <div class="card-body">
            <h6>Dev features (root)</h6>
            <div class="row">
              <div class="w-85">
                <span>Бачити dev features</span>
              </div>
              <div class="w-15 form-check form-switch text-end">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="hideDevFeaturesAnywaySwitcher"
                  @change="hideDevFeaturesAnywaySwitcherChanged"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Telegram -->
        <div class="card mb-2" id="telegram">
          <div class="card-body">
            <div class="row mt-2 mb-2">
              <div class="col">
                <h3 class="mb-0">Telegram bot</h3>
              </div>
              <div class="col text-end">
                <span
                  class="badge badge-success ms-auto mb-auto"
                  v-if="profile.telegramChatId"
                  >{{ profile.telegramChatId }}</span
                >
              </div>
            </div>
            <p class="text-sm">
              Підпишіться на Telegram бота, щоб миттево отримувати важливі
              повідомлення.
            </p>
            <div class="d-flex">
              <a
                class="my-auto btn btn-sm btn-outline-dark mb-0"
                href="https://t.me/Bookmenowbot"
                target="_blank"
                role="button"
                >Підписатись</a
              >
            </div>
          </div>
        </div>
        <!-- Telegram finish -->

        <!-- Дополнительные настройки -->
        <div class="card" id="additional-settings">
          <div class="card-body">
            <h3>Telegram нотифікації</h3>
            <div class="row">
              <div class="w-85">
                <span>
                  Сповіщати про нову бронь зі сторінки онлайн бронювання
                </span>
              </div>
              <div class="w-15 form-check form-switch text-end">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="
                    profile.telegramNotifications.serviceOrderFromOnlineFormNew
                  "
                />
              </div>
            </div>
            <hr />
            <div class="row mt-2">
              <div class="w-85">
                <span> Сповіщати про новий відгук </span>
              </div>
              <div class="w-15 form-check form-switch text-end">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="profile.telegramNotifications.reviewNew"
                />
              </div>
            </div>
            <hr />
            <div class="row mt-2">
              <div class="w-85">
                <span> Сповіщати про нову бронь створену в адмін панелі </span>
              </div>
              <div class="w-15 form-check form-switch text-end">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="
                    profile.telegramNotifications.serviceOrderFromAdminAppNew
                  "
                />
              </div>
            </div>
            <hr />
            <div class="row mt-2">
              <div class="w-85">
                <span> Сповіщати про зміни у бронюваннях </span>
              </div>
              <div class="w-15 form-check form-switch text-end">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="profile.telegramNotifications.serviceOrderChanges"
                />
              </div>
            </div>
            <hr />
            <div class="row mt-2">
              <div class="w-85">
                <span> Сповіщати про скасування бронювань </span>
              </div>
              <div class="w-15 form-check form-switch text-end">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="profile.telegramNotifications.serviceOrderCancelled"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Дополнительные настройки finish -->

        <!-- Change Password -->
        <div class="card mt-2" id="password">
          <div class="card-body">
            <h3>Змінити пароль до адмін панелі</h3>
            <div class="row">
              <div class="col-lg-6">
                <label class="form-label">Новий пароль</label>
                <div class="input-group input-group-outline">
                  <input
                    type="password"
                    class="form-control"
                    v-model="newPassword"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <label class="form-label">Підтвердіть новий пароль</label>
                <div class="input-group input-group-outline">
                  <input
                    type="password"
                    class="form-control"
                    v-model="newPasswordConfirm"
                  />
                </div>
              </div>
            </div>
            <button
              class="btn btn-outline-primary btn-sm mt-2 float-end mb-0"
              :disabled="!isValidPassword()"
              @click="resetPassword"
            >
              Змінити пароль
            </button>
          </div>
        </div>
        <!-- Change Password finish -->
        <!-- Card Удалить менеджера -->
        <div class="card mt-2 mb-2" id="delete">
          <div class="card-body">
            <div class="row">
              <h3>Видалити</h3>
              Ви можете видалити свій профіль. Видаливши, ви втратите доступ до
              свого акаунту після видалення.
            </div>
            <div class="row">
              <div class="text-end">
                <button
                  class="btn bg-gradient-danger btn-sm mb-0 ms-2"
                  type="button"
                  name="button"
                  data-bs-toggle="modal"
                  :data-bs-target="`#${modalParamsRemoveProfile.modalId}`"
                >
                  Видалити
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Удалить менеджера finish -->

        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="modalParamsRemoveProfile"></BmnCustomPopup>
</template>

<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.container-fluid {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.card {
  border-radius: 0px;
}
</style>

<script>
import api from "@/services/api";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";
import Spinner from "@/views/shared/Spinner.vue";

export default {
  data() {
    return {
      dataLoaded: false,
      showDevFeatures: false,
      hideDevFeaturesAnyway: false,
      hideDevFeaturesAnywaySwitcher: true,

      newPassword: "",
      newPasswordConfirm: "",

      profile: {
        userId: null,
        telegramChatId: null,
        telegramNotifications: {
          serviceOrderFromOnlineFormNew: false,
          serviceOrderFromAdminAppNew: false,
          serviceOrderChanges: false,
        },
      },

      modalParamsRemoveProfile: {
        modalId: "modal-default-1",
        modalTitle: "Видалення",
        modalDescription:
          "Ви впевнені, що хочете видалити свій профіль? Після видалення, профіль відновити неможливо.",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Видалити",
        data: {},
        confirmHandler: async () => {
          try {
            await api.myprofile.remove();

            this.$store.commit("addToast", {
              title: "Збережено",
            });

            window.location.href = "/authentication/logout";
          } catch (error) {
            this.$store.commit("addToast", {
              title: "Виникла помилка. Спробуйте ще раз",
              type: "error",
            });
          }
        },
      },
    };
  },

  async created() {
    try {
      this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
      this.hideDevFeaturesAnyway = !!localStorage.getItem(
        "hideDevFeaturesAnyway",
      );
      this.hideDevFeaturesAnywaySwitcher = !this.hideDevFeaturesAnyway;

      const user = await api.myprofile.show();

      this.profile.userId = user._id;
      this.profile.telegramChatId = user.telegramChatId;
      this.profile.telegramNotifications = user.telegramNotifications || {};
      this.profile.telegramNotifications.serviceOrderFromOnlineFormNew =
        user.telegramNotifications?.serviceOrderFromOnlineFormNew || false;
      this.profile.telegramNotifications.serviceOrderFromAdminAppNew =
        user.telegramNotifications?.serviceOrderFromAdminAppNew || false;
      this.profile.telegramNotifications.serviceOrderChanges =
        user.telegramNotifications?.serviceOrderChanges || false;
      this.profile.telegramNotifications.reviewNew =
        user.telegramNotifications?.reviewNew || false;
    } catch (error) {
      console.error(error);
    }
    this.dataLoaded = true;
  },

  components: {
    BmnCustomPopup,
    Spinner,
  },

  methods: {
    isValidPassword() {
      if (this.newPassword && this.newPassword === this.newPasswordConfirm) {
        return true;
      }

      return false;
    },

    async resetPassword() {
      try {
        await api.user.resetPassword(this.profile.userId, {
          password: this.newPassword,
          self: true,
        });

        this.$store.commit("addToast", {
          title: "Пароль змінено",
        });

        this.newPassword = "";
        this.newPasswordConfirm = "";
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    async save() {
      try {
        await api.myprofile.update({
          telegramNotifications: this.profile.telegramNotifications,
        });
        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    hideDevFeaturesAnywaySwitcherChanged() {
      const value = !this.hideDevFeaturesAnywaySwitcher ? "y" : "";
      localStorage.setItem("hideDevFeaturesAnyway", value);
      window.location.reload();
    },
  },
};
</script>
