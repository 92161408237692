<script setup>
import { ref, watch } from "vue";
import draggable from "vuedraggable";
import * as _ from "lodash";

const emit = defineEmits(["update:modelValue", "remove", "update"]);
const props = defineProps(["modelValue"]);
const photos = ref(props.modelValue);
const dragOptions = {
  animation: 500,
  disabled: false,
  ghostClass: "ghost",
};

function remove(photo) {
  photos.value = photos.value.filter((item) => item._id !== photo._id);

  emit("remove", photo);
}

watch(
  () => props.modelValue,
  () => {
    photos.value = props.modelValue;
  },
  { deep: true },
);

const oldPhotos = ref();

watch(
  () => photos,
  () => {
    photos.value.map((item, index) => {
      item.rank = index + 1;
      return item;
    });

    if (!_.isEqual(oldPhotos.value, photos.value)) {
      oldPhotos.value = _.cloneDeep(photos.value);

      emit("update", photos.value);
      emit("update:modelValue", photos.value);
    }
  },
  { deep: true },
);
</script>

<template>
  <div class="vgt-responsive" v-if="photos">
    <table class="custom-table vgt-table">
      <thead class="thead-dark">
        <tr>
          <th
            scope="col"
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            style="width: 50px"
          ></th>
          <th
            scope="col"
            class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 thead th"
            aria-controls="col-1"
          >
            <span>Фото</span>
          </th>
          <th
            scope="col"
            class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            aria-controls="col-1"
          ></th>
        </tr>
      </thead>
      <draggable
        v-model="photos"
        tag="tbody"
        item-key="_id"
        handle=".handle"
        v-bind="dragOptions"
      >
        <template #item="{ element }">
          <tr>
            <td>
              <div class="text-center disableCopyElement">
                <i class="material-icons text-lg me-2 grab-box handle">
                  drag_indicator</i
                >
              </div>
            </td>
            <td
              class="vgt-left-align text-xs font-weight-normal disableCopyElement"
            >
              <a target="_blank" :href="element.url">
                <img alt="staff photo" :src="element.url" class="img" />
              </a>
            </td>
            <td
              class="vgt-left-align text-xs font-weight-normal disableCopyElement"
            >
              <a
                class="nav-link text-danger d-flex c-width-200"
                data-scroll=""
                href="#"
              >
                <i class="material-icons text-lg me-2">delete</i>
                <span class="text-sm" @click="remove(element)">Видалити</span>
              </a>
            </td>
          </tr>
        </template>
      </draggable>
    </table>
  </div>
</template>

<style scoped lang="scss">
.disableCopyElement {
  -webkit-touch-callout: none; /* Отключение контекстного меню iOS */
  -webkit-user-select: none; /* Отключение выбора текста в Safari */
  -khtml-user-select: none; /* Отключение выбора текста в Konqueror HTML */
  -moz-user-select: none; /* Отключение выбора текста в Firefox */
  -ms-user-select: none; /* Отключение выбора текста в Internet Explorer/Edge */
  user-select: none; /* Отключение выбора текста в большинстве современных браузеров */
}
table.vgt-table td {
  vertical-align: middle;
}
tr {
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background: #fbfbfb;
  }
}
.ghost {
  opacity: 0.7;
  background: #d7d6d6;
}
.img {
  max-width: 100%;
}
</style>
