<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mt-2">
      <div class="card" id="basic-info">
        <div class="card-body pt-0 mx-3">
          <div class="row mt-3">
            <h4 class="heading">Знижки на тривале перебування</h4>
            <div class="col-lg-12">
              <div class="row">
                <div class="row">
                  Бронюючи на декілька днів гості зможуть отримувати знижку
                </div>
                <div class="row add-more-discount mt-3">
                  <div class="col-lg-3">
                    <label class="custom-form-label"
                      >Кількість діб в броюванні</label
                    >
                    <div class="input-group input-group-outline">
                      <input
                        v-model="entityTemplate.thresholdDays"
                        class="form-control"
                        placeholder="0 діб"
                        type="tel"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <label class="custom-form-label">Знижка %</label>
                    <div class="input-group input-group-outline">
                      <input
                        v-model="entityTemplate.amount"
                        class="form-control"
                        placeholder="0 %"
                        type="tel"
                      />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-lg-3 text-center">
                      <button
                        @click="save"
                        :disabled="!isValid()"
                        class="btn mt-2 btn-md btn-primary"
                        type="button"
                      >
                        Зберегти
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../../services/api";
import Spinner from "@/views/shared/Spinner.vue";

export default {
  name: "settings-hotel-discounts",

  components: {
    Spinner,
  },

  async mounted() {
    if (this.$route.params.id) {
      this.mode = "edit";
      this.entityTemplate = await api.hotelDiscounts.findOne(
        this.$route.params.id,
      );
    } else {
      this.mode = "create";
    }

    this.dataLoaded = true;
  },

  data() {
    return {
      dataLoaded: false,
      mode: "", // create | edit

      entityTemplate: {
        name: "",
        description: "",
        type: "percentage",
        amount: null,
        thresholdDays: null,
        isActive: true,
      },
    };
  },

  methods: {
    async save() {
      try {
        if (this.mode === "create") {
          await api.hotelDiscounts.create(this.entityTemplate);
        } else if (this.mode === "edit") {
          await api.hotelDiscounts.updateOne(
            this.$route.params.id,
            this.entityTemplate,
          );
        }

        this.$store.commit("addToast", {
          title: "Збережено",
        });

        await this.$router.push("/settings/discounts");
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    isValid() {
      if (!this.entityTemplate.amount) {
        return false;
      }
      if (!this.entityTemplate.thresholdDays) {
        return false;
      }
      if (this.entityTemplate.thresholdDays <= 0) {
        return false;
      }
      if (this.entityTemplate.amount <= 0) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.underline-link {
  text-decoration: underline;
}
.created-discount {
  border: 1px solid #ccc; /* Тонкая серая рамка */
  border-radius: 8px; /* Скругленные углы */
  padding: 16px; /* Внутренние отступы */
  margin-bottom: 10px; /* Нижний отступ */
}
.discount-value {
  color: black;
  font-size: 12pt;
}
.btn-primary {
  width: 100%;
}
.heading {
  padding-left: 0px !important;
  margin-bottom: 0px;
}
.custom-form-label {
  margin-bottom: 0px;
}
</style>
