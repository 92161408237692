<script setup>
import { computed } from "vue";

const props = defineProps({
  items: { type: Array, required: true },
  modelValue: { type: [String, Boolean, Number], required: true },
});
const emit = defineEmits(["update:modelValue"]);

const model = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});
const name = computed(() => {
  return props.items?.map((item) => item.value).join("");
});
</script>
<template>
  <div
    class="btn-group w-100"
    role="group"
    aria-label="Basic radio toggle button group"
  >
    <template v-for="item of items" :key="item.id">
      <input
        type="radio"
        class="btn-check"
        :name="'btnradio' + name"
        :id="item.id"
        autocomplete="off"
        :value="item.value"
        v-model="model"
      />
      <label
        class="btn btn-outline-primary mb-0"
        :class="{
          active: model === item.value,
        }"
        :for="item.id"
      >
        <i v-if="item.icon" class="material-symbols-outlined material-icons">{{
          item.icon
        }}</i>
        {{ item.text }}
      </label>
    </template>
  </div>
</template>
<style scoped lang="scss">
.btn-group {
  padding: 0;
}
.btn {
  height: 35px;
  padding: 0.375rem 1.5rem;
  &.active {
    color: #fff !important;
    background-color: #387459 !important;
  }
}
</style>
