<script setup>
import { useStore } from "vuex";
import api from "@/services/api";
import { computed, onMounted, onBeforeMount, watch, shallowRef } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useCompanyStore } from "@/storePinia/company";
import { useFetch } from "@/composables/common/api/useFetch";
import ToastApp from "@/components/ToastApp.vue";
import { layouts } from "@/utils/lists/layouts";

const router = useRouter();
const route = useRoute();
const store = useStore();

const layout = shallowRef(layouts.AuthLayout);

const storeCompany = useCompanyStore();

onBeforeMount(async () => {
  let tenantName;
  try {
    const localUser = localStorage.getItem("localUser");
    if (localUser) {
      const parsed = JSON.parse(localUser);
      tenantName = parsed.tenantName;
      // TODO: move to Pinia. Also move to `main.js` file
      store.commit("setTenantName", tenantName);
      layout.value = layouts.DefaultLayout;
    }
  } catch (error) {
    console.error(error);
  }

  store.state.isTransparent = "bg-transparent";

  const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

  if (window.innerWidth > 1200) {
    sidenav.classList.add("g-sidenav-pinned");
  }
});

const {
  data: positionList,
  isLoading: isLoadingPosition,
  fetchData: getPositionList,
} = useFetch(api.positions.list);

const {
  data: staffList,
  isLoading: isLoadingStaff,
  fetchData: getStaffList,
} = useFetch(api.staff.list);

const {
  data: serviceList,
  isLoading: isLoadingService,
  fetchData: getServiceList,
} = useFetch(api.services.list);

onMounted(async () => {
  await getPositionList();
  await getStaffList();
  await getServiceList();
});

const isLoading = computed(() => {
  return (
    isLoadingStaff.value || isLoadingPosition.value || isLoadingService.value
  );
});

const isEmptyTenant = computed(() => {
  let result = false;
  if (!storeCompany.companyIsHotel) {
    if (
      positionList.value?.length === 0 &&
      serviceList.value?.length === 0 &&
      staffList.value?.length === 0
    ) {
      result = true;
    } else {
      result = false;
    }
  }
  return result;
});

watch(
  isEmptyTenant,
  (val) => {
    if (val) {
      storeCompany.setStatusTenant(val);
      router.push("/beauty/onboarding/intro");
    }
  },
  { immediate: true },
);
</script>

<template>
  <toast-app></toast-app>
  <component :is="layout" :isLoading="isLoading" />
</template>
