<script setup>
import { ref, onMounted, watch } from "vue";
import { useFetch } from "@/composables/common/api/useFetch";
import api from "@/services/api";
import SelectApp from "@/components/common/SelectApp.vue";
import * as _ from "lodash";

const props = defineProps({
  modelValue: {
    type: Object,
  },
});
const emit = defineEmits(["update:modelValue"]);
const selectedItem = ref();
const options = ref([]);

const {
  data: items,
  isLoading,
  fetchData: getItems,
} = useFetch(api.staff.list);

watch(
  () => selectedItem.value,
  () => {
    emit("update:modelValue", selectedItem.value);
  },
  { deep: true },
);

onMounted(async () => {
  await getItems([{ isActive: 1 }]);

  let resultArr = [];
  items.value.forEach((item) => {
    let categoryIndex = resultArr.findIndex(
      (category) => category.categoryId === item.positionId._id,
    );

    const roomItem = {
      label: `${item.name}`,
      id: item._id,
    };

    if (categoryIndex >= 0) {
      resultArr[categoryIndex].rooms.push(roomItem);
    } else {
      const categoryName = item.positionId.name;
      const categoryId = item.positionId._id;
      const rank = item.positionId.rank;

      const itemWithCategory = {
        categoryName,
        categoryId,
        rank,
        rooms: [roomItem],
      };
      resultArr.push(itemWithCategory);
    }
  });

  resultArr = _.sortBy(resultArr, "rank");
  options.value = options.value.concat(resultArr);
  selectedItem.value = options.value[0].rooms[0];
});
</script>

<template>
  <SelectApp
    v-model="selectedItem"
    :settings="{
      allowEmpty: false,
      loading: isLoading,
      options: options,
      trackBy: 'label',
      label: 'label',
      groupLabel: 'categoryName',
      groupValues: 'rooms',
      trackBy: 'label',
      placeholder: 'Оберіть номер',
    }"
  />
</template>
