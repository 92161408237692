<script setup>
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import { computed, ref } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  nameField: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["nextStep"]);
const name = ref(props.modelValue);
const isValidName = computed(() => {
  return name.value?.trim().length > 2;
});

function nextStep() {
  emit("nextStep", name.value, props.nameField);
}
</script>
<template>
  <div>
    <div class="row">
      <div class="col-12 text-center">
        <h2>Назва обʼєкту розміщення</h2>
      </div>
    </div>
    <div class="card-body pt-0 mb-5">
      <div class="row text-center">
        <p>Вкажіть як ваш номер або будиночок називається</p>
      </div>
      <div class="d-flex justify-content-center">
        <div class="max-width-300 w-100">
          <div class="input-group input-group-outline">
            <textarea
              v-model="name"
              type="text"
              rows="1"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <!-- <div class="row mt-5">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
          <ButtonApp
            :disabled="!isValidName"
            @click="nextStep"
            :class="'btn-icon bg-gradient-primary'"
          >
            Продовжити
          </ButtonApp>
        </div>
      </div> -->

      <!--  Продовжити -->
      <div class="fixed-bottom mb-2">
        <div class="row">
          <div class="col-10"></div>
          <div class="col-lg-2">
            <div class="mx-4">
              <button
                type="button"
                class="block btn bg-gradient-primary"
                :disabled="!isValidName"
                @click="nextStep"
              >
                <span> Продовжити</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--  Продовжити фiнiш-->
    </div>
  </div>
</template>
<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>
