<script setup>
import flatPickr from "vue-flatpickr-component";
import Multiselect from "vue-multiselect";
import SmallLoader from "@/views/shared/SmallLoader.vue";
import { EVENTS_UPDATE_MODAL } from "@/utils/enums/events";
import { DateTime } from "luxon";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { Ukrainian } from "flatpickr/dist/l10n/uk";
import { getIntervalsFromISO, ISODateToDay } from "@/utils/time";
import api from "@/services/api";
import { sizes } from "@/utils/constants/common";

import FullCalendarWrapper from "@/components/fullCalendar/FullCalendarWrapper.vue";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";

import { useModal } from "@/composables/common/modal";
import { useBreakpoints } from "@/composables/common/useBreakpoint";
import { useDaysSelection } from "@/composables/common/daysSelection";
import { useFullCalendar } from "@/composables/common/fullCalendar";
import { useFetch } from "@/composables/common/api/useFetch";
import { colors } from "@/utils/enums/colors";

import { useScheduleSetting } from "@/composables/scheduleSetting";
import SelectApp from "@/components/common/SelectApp.vue";

const {
  allDay,
  selectedDays,
  days,
  dateRange,
  dateNormalize,
  selectedItems: selectedRooms,
  isLoading,
  timeWork,
  timeBreak,
  configFlatpickr,
  configFlatpickrFreestyle,
  configFlatpickrTime,
  freestyleDates,
  _freestyleDates,
  isFreestyleWorkingSchedule,
  saveWorkingDays,
  reset,
} = useScheduleSetting({
  onSuccess: () => {
    updateCalendar();
    closeModalThis();
    closeOneStaffModal();
  },
});

const { data: dataResources, fetchData: getResourcesApi } = useFetch(
  api.staff.list,
);

const {
  openModal: openModalThis,
  closeModal: closeModalThis,
  modalRef: modalRefAll,
} = useModal();

const {
  openModal: openOneStaffModal,
  closeModal: closeOneStaffModalThis,
  modalRef: modalOneStaffRef,
} = useModal();

function closeOneStaffModal() {
  closeOneStaffModalThis();
  reset();
}

const { isMobile } = useBreakpoints();

const {
  calendarLoaded,
  defaultCalendarOptions,
  calendarRefForWrapper,
  resources,
  updateCalendar,
} = useFullCalendar(getEvents, getResources, false);

const calendarOptions = ref({
  ...defaultCalendarOptions.value,
  initialView: "dayGridMonth",
  plugins: [interactionPlugin, dayGridPlugin],
});

const mobileView = ref("normal");
watch(mobileView, (newValue) => {
  calendarOptions.value.slotMinWidth =
    newValue === "smaller" && isMobile.value
      ? sizes.MIN_WIDTH_SLOT_FULLCALENDAR_MOB_SMALLER
      : sizes.MIN_WIDTH_SLOT_FULLCALENDAR_PC;
  updateCalendar();
  localStorage.setItem("mobileView2", newValue);
});
onMounted(() => {
  if (localStorage.mobileView2) {
    mobileView.value = localStorage.mobileView2;
  }
});

const optionsSelect = computed(() => {
  return resources.value?.map((item) => ({
    name: item.title,
    code: item.id,
  }));
});

const disabledButtonDelete = computed(() => {
  if (selectedRooms.value.length === 0) {
    return true;
  }

  if (isFreestyleWorkingSchedule.value) {
    return freestyleDates.value.length === 0;
  } else {
    return !dateNormalize.value || !selectedDays.value.length;
  }
});

function openModal() {
  openModalThis();
}

function closeModal() {
  closeModalThis();
  reset();
}

const isValid = computed(() => {
  if (!selectedRooms.value.length) {
    return false;
  }
  if (!timeWork.value.from || !timeWork.value.to) {
    return false;
  }

  if (isFreestyleWorkingSchedule.value) {
    return freestyleDates.value.length > 0;
  } else {
    return dateNormalize.value && selectedDays.value.length > 0;
  }
});

async function getResources() {
  const params = {
    isActive: "1",
  };
  await getResourcesApi([params]);
  dataResources.value = dataResources.value.map((item) => {
    return {
      id: item._id,
      title: item.name,
      rank: item.rank,
      quest: item.checkinRules.guestsMax,
    };
  });
  resources.value = dataResources.value;
}

onMounted(() => {
  getResources();
});

async function getEvents(resourcesList = [], { dateFrom = "", dateTo = "" }) {
  let eventsList = [];
  await Promise.all(
    resourcesList.map(async (item, index) => {
      const result = await api.staff.getWorkingHoursRange(item.id, {
        startDate: dateFrom,
        endDate: dateTo,
      });
      if (!result) {
        return;
      }
      const eventsForRoom = result.map((itemDay) => {
        return {
          staffId: item.id,
          id: `${item.date}${DateTime.now().toISODate()}`,
          start: itemDay.date,
          name: item.title,
          dateEvent: itemDay.date,
          breakFrom: itemDay.breakFrom,
          workFrom: itemDay.from,
          workTo: itemDay.to,
          breakTo: itemDay.breakTo,
          color: colors[`COLOR_${index + 1}`],
          textDateRange: `${itemDay.from}-${itemDay.to}`,
          isValid: itemDay.from && itemDay.to,
        };
      });
      eventsList = [...eventsList, ...eventsForRoom];
    }),
  );
  return eventsList;
}

const oneStaffPayload = ref(null);
const titleForModal = computed(() => {
  if (!oneStaffPayload.value) {
    return "";
  }
  const date = ISODateToDay(oneStaffPayload.value.dateEvent);
  return oneStaffPayload.value.name + " " + date;
});

function eventClick(event) {
  oneStaffPayload.value = event;
  timeBreak.value.from = event.breakFrom;
  timeBreak.value.to = event.breakTo;
  dateRange.value = event.dateEvent;
  timeWork.value.from = event.workFrom;
  timeWork.value.to = event.workTo;
  selectedRooms.value.push({
    code: event.staffId,
    name: event.name,
  });

  openOneStaffModal();
}
</script>
<template>
  <div class="wrap mb-3 mt-3">
    <div class="card-calendar">
      <div class="card-body">
        <div
          class="d-flex row row-cols-md-auto flex-wrap flex-md-nowrap justify-content-between mb-3"
        >
          <div class="col-12 mb-2 mb-md-0 col-md-auto">
            <button
              class="btn btn-sm bg-gradient-primary w-100 mb-0 h-100"
              type="button"
              @click="openModal(EVENTS_UPDATE_MODAL)"
            >
              Встановити графік
            </button>
          </div>
        </div>
        <FullCalendarWrapper
          ref="calendarRefForWrapper"
          :calendar-loaded="calendarLoaded"
          :is-small-on-mobile="mobileView === 'smaller'"
          :options="calendarOptions"
        >
          <template #calendar-content="{ arg }">
            <div
              v-if="arg.event.extendedProps.isValid"
              :style="{ backgroundColor: arg.event.extendedProps.color }"
              class="event-schedule"
              @click="eventClick(arg.event.extendedProps)"
            >
              {{ arg.event.extendedProps.name }} <br />
              {{ arg.event.extendedProps.textDateRange }}
            </div>
          </template>
        </FullCalendarWrapper>
        <div class="worktime__staffs">
          <div
            v-for="(item, index) of resources"
            :key="item.id"
            :style="{ backgroundColor: colors[`COLOR_${index + 1}`] }"
            class="worktime__staff"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    id="exampleModalConstructor"
    ref="modalRefAll"
    aria-hidden="true"
    aria-labelledby="exampleModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-weight-normal">Встановити графік</h5>
          <button
            aria-label="Close"
            class="btn-close text-dark"
            type="button"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group input-group-static">
            <div class="row">Оберіть майстрів (можна декілька)</div>
            <SelectApp
              v-model="selectedRooms"
              :settings="{
                allowEmpty: false,
                options: optionsSelect,
                placeholder: 'Оберіть майстрів',
                trackBy: 'code',
                closeOnSelect: false,
                taggable: true,
                multiple: true,
                label: 'name',
              }"
            />
          </div>
          <div class="row mt-3">
            <div class="row">Робочий час</div>
            <div class="col-5 text-center">
              <label class="d-block">Початок</label>
              <flat-pickr
                v-model="timeWork.from"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="Оберіть час"
              ></flat-pickr>
            </div>
            <div class="col-5 text-center">
              <label class="d-block">Кінець</label>
              <flat-pickr
                v-model="timeWork.to"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="--:--"
              ></flat-pickr>
            </div>
          </div>
          <div class="row mt-3">
            <div class="row">Перерва</div>
            <div class="col-5 text-center">
              <label>Початок</label>
              <flat-pickr
                v-model="timeBreak.from"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="--:--"
              ></flat-pickr>
            </div>
            <div class="col-5 text-center">
              <label class="d-block">Кінець</label>
              <flat-pickr
                v-model="timeBreak.to"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="--:--"
              ></flat-pickr>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">Встановіть період дат</div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <div class="input-group input-group-static">
                <div class="form-check">
                  або оберіть дати freestyle
                  <input
                    id="customCheckAll"
                    v-model="isFreestyleWorkingSchedule"
                    class="form-check-input mx-2"
                    type="checkbox"
                  />
                </div>
                <div v-show="!isFreestyleWorkingSchedule" style="width: 100%">
                  <flat-pickr
                    v-show="!isFreestyleWorkingSchedule"
                    v-model="dateRange"
                    :config="configFlatpickr"
                    :type="'range'"
                    class="form-control datetimepicker text-xs"
                    placeholder="Оберіть дати (діапазон)"
                  ></flat-pickr>
                </div>
                <div v-show="isFreestyleWorkingSchedule" style="width: 100%">
                  <flat-pickr
                    v-show="isFreestyleWorkingSchedule"
                    v-model="_freestyleDates"
                    :config="configFlatpickrFreestyle"
                    :type="'multiple'"
                    class="form-control datetimepicker text-xs"
                    placeholder="Оберіть дати (freestyle)"
                  ></flat-pickr>
                </div>
              </div>
              <div v-show="!isFreestyleWorkingSchedule" class="mt-3">
                <div class="row">Оберіть дні тижня:</div>
                <div class="form-check">
                  <input
                    id="customCheckAll"
                    v-model="allDay"
                    class="form-check-input mx-2"
                    type="checkbox"
                  />
                  <label
                    class="custom-control-label text-xs"
                    for="customCheckAll"
                    >Усі дні</label
                  >
                </div>
                <div v-for="item of days" :key="item.number" class="form-check">
                  <input
                    :id="'customCheck' + item.number"
                    v-model="item.isActive"
                    class="form-check-input mx-2"
                    type="checkbox"
                    value=""
                  />
                  <label
                    :for="'customCheck' + item.number"
                    class="custom-control-label text-xs"
                    >{{ item.label }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="closeModal"
          >
            закрити
          </button>
          <button
            :disabled="isLoading || disabledButtonDelete"
            class="btn btn-outline-danger"
            type="button"
            @click="saveWorkingDays(true)"
          >
            <SmallLoader v-if="isLoading" />
            <span v-else> Видалити</span>
          </button>
          <button
            :disabled="isLoading || !isValid"
            class="btn bg-gradient-primary"
            type="button"
            @click="saveWorkingDays()"
          >
            <SmallLoader v-if="isLoading" />
            <span v-else> Зберегти</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="exampleModalConstructor2"
    ref="modalOneStaffRef"
    aria-hidden="true"
    aria-labelledby="exampleModalLabel2"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-weight-normal">{{ titleForModal }}</h5>
          <button
            aria-label="Close"
            class="btn-close text-dark"
            type="button"
            @click="closeOneStaffModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mt-3">
            <div class="row">Робочий час</div>
            <div class="col-5 text-center">
              <label class="d-block">Початок</label>
              <flat-pickr
                v-model="timeWork.from"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="Оберіть час"
              ></flat-pickr>
            </div>
            <div class="col-5 text-center">
              <label class="d-block">Кінець</label>
              <flat-pickr
                v-model="timeWork.to"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="--:--"
              ></flat-pickr>
            </div>
          </div>
          <div class="row mt-3">
            <div class="row">Перерва</div>
            <div class="col-5 text-center">
              <label class="d-block">Початок</label>
              <flat-pickr
                v-model="timeBreak.from"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="--:--"
              ></flat-pickr>
            </div>
            <div class="col-5 text-center">
              <label class="d-block">Кінець</label>
              <flat-pickr
                v-model="timeBreak.to"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="--:--"
              ></flat-pickr>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="closeOneStaffModal"
          >
            закрити
          </button>
          <button
            :disabled="isLoading || disabledButtonDelete"
            class="btn btn-outline-danger"
            type="button"
            @click="saveWorkingDays(true)"
          >
            <SmallLoader v-if="isLoading" />
            <span v-else> Видалити</span>
          </button>
          <button
            :disabled="isLoading || !isValid"
            class="btn bg-gradient-primary"
            type="button"
            @click="saveWorkingDays()"
          >
            <SmallLoader v-if="isLoading" />
            <span v-else> Зберегти</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.fc-day-other) {
  pointer-events: none;
}
:deep(.fc-day-other .fc-daygrid-day-number),
:deep(.fc-day-other .fc-daygrid-day-events) {
  opacity: 0;
}
.form-control.datetimepicker.flatpickr-input {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}

.card-calendar {
  background: #fff;
}

:deep(.fc .fc-toolbar-title) {
  font-size: small;
}

:deep(.fc .fc-daygrid-day-number) {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 10px;
  }
}

:deep(.fc-event.fc-daygrid-event) {
  @media (max-width: 768px) {
    border-radius: 4px;
  }
}

.event-schedule {
  text-align: center;
  font-size: 12px;
  color: black;
  cursor: pointer;
  padding: 6px;
  @media (max-width: 991px) {
    font-size: 10px;
  }

  br {
    display: none;
    @media (max-width: 768px) {
      display: inline;
    }
  }

  @media (max-width: 768px) {
    font-size: 7px;
    padding: 4px;
  }
  @media (max-width: 450px) {
    font-size: 6px;
  }
}

.worktime__staffs {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  min-width: 150px;
  margin-top: 40px;
  @media (max-width: 768px) {
    min-width: 100px;
  }
}

.worktime__staff {
  display: inline-block;
  font-size: 14px;
  color: black;
  padding: 4px 16px;
  border-radius: 4px;
  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.form-check {
  padding-left: 0;
}
</style>
