<template>
  <!-- ALL WAREHOUSE IS NOW MVP -->
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-6">
        <h4>Новый товар</h4>
      </div>
      <div
        class="text-right col-lg-6 d-flex flex-column justify-content-center"
      ></div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4">
        <div class="card mt-4" data-animation="true">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <a class="d-block blur-shadow-image">
              <img
                src="../../../../assets/img/products/product-11.jpg"
                alt="img-blur-shadow"
                class="img-fluid shadow border-radius-lg"
              />
            </a>
            <div
              class="colored-shadow"
              style="background-image: url(&quot;#&quot;)"
            ></div>
          </div>
          <div class="card-body text-center">
            <div class="mt-n6 mx-auto">
              <button
                class="btn bg-gradient-primary btn-sm mb-0 me-2"
                type="button"
                name="button"
              >
                Ред
              </button>
              <button
                class="btn btn-outline-dark btn-sm mb-0"
                type="button"
                name="button"
              >
                Удалить
              </button>
            </div>
            <h5 class="font-weight-normal mt-4">Фото товара</h5>
            <p class="mb-0"></p>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Общая информация</h5>
            <div class="row mt-4">
              <div class="col-12 col-sm-6">
                <VmdInput variant="dynamic" label="Название" />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <VmdInput variant="dynamic" label="Бренд" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <label class="mt-4 ms-0">Описание</label>
                <p class="text-xs form-text text-muted ms-1 d-inline">
                  (optional)
                </p>
                <div id="edit-description-edit" class="h-50">
                  Добавьте описание товара
                </div>
              </div>
              <div class="col-sm-6">
                <label class="mt-4 ms-0">Ед. измерения</label>
                <select
                  class="form-control"
                  name="choices-category"
                  id="choices-category-edit"
                >
                  <option value="Choice 1" selected>шт</option>
                  <option value="Choice 5">кг</option>
                  <option value="Choice 5">грамм</option>
                  <option value="Choice 5">л; дм3</option>
                  <option value="Choice 2">дюйм</option>
                  <option value="Choice 4">ц</option>
                  <option value="Choice 5">мм</option>
                  <option value="Choice 5">дюйм3</option>
                  <option value="Choice 5">см2</option>
                  <option value="Choice 5">ящ</option>
                  <option value="Choice 5">дм2</option>
                </select>
                <label class="mt-3 ms-0">Поставщик</label>
                <select
                  class="form-control"
                  name="choices-color"
                  id="choices-color-edit"
                >
                  <option value="Choice 1" selected>Loral</option>
                  <option value="Choice 2">P&G</option>
                  <option value="Choice 3">Поставщик 3</option>
                  <option value="Choice 4">Поставщик 4</option>
                  <option value="Choice 5">Поставщик 5</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 row">
      <div class="col-sm-4">
        <div class="card"></div>
      </div>
      <div class="mt-2 col-sm-8 mt-sm-0">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h5 class="font-weight-bolder mb-4">Цена</h5>
              <div class="col-3">
                <vmd-input label="Цена поставки" variant="dynamic" />
              </div>
              <div class="col-3">
                <vmd-input label="Цена продажи" variant="dynamic" />
              </div>
              <div class="col-3">
                <vmd-input label="Количество" variant="dynamic" />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <select
                  class="form-control"
                  name="choices-sizes"
                  id="choices-currency-edit"
                >
                  <option value="Choice 1" selected>Валюта</option>
                  <option value="Choice 2">UAH</option>
                  <option value="Choice 2">EUR</option>
                  <option value="Choice 3">GBP</option>
                  <option value="Choice 4">CNY</option>
                  <option value="Choice 5">INR</option>
                  <option value="Choice 6">BTC</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6"></div>
          <div
            class="text-right col-lg-6 d-flex flex-column justify-content-center"
          >
            <button
              type="button"
              class="mt-2 mb-0 btn bg-gradient-primary ms-lg-auto me-lg-0 me-auto mt-lg-0"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
import Choices from "choices.js";
import VmdInput from "@/components/VmdInput.vue";

export default {
  name: "edit-product",

  components: { VmdInput },
  mounted() {
    this.getChoices("choices-category-edit");
    this.getChoices("choices-color-edit");
    this.getChoices("choices-currency-edit");

    if (document.getElementById("choices-tags-edit")) {
      var tags = document.getElementById("choices-tags-edit");
      const examples = new Choices(tags, {
        removeItemButton: true,
      });

      examples.setChoices(
        [
          {
            value: "One",
            label: "Expired",
            disabled: true,
          },
          {
            value: "Two",
            label: "Out of Stock",
            selected: true,
          },
        ],
        "value",
        "label",
        false,
      );
    }
  },
  methods: {
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
        });
      }
    },
  },
};
</script>
