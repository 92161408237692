<script setup>
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import { integerTransformer, useRouteQuery } from "vue-use-route-query";
import InputApp from "@/components/common/inputs/InputApp.vue";
import { computed, onMounted, ref } from "vue";
import { useFetch } from "@/composables/common/api/useFetch";
import api from "@/services/api";
import { useRouter } from "vue-router";

const router = useRouter();

const name = useRouteQuery("namePosition", "");
const durationHours = useRouteQuery("durationHours", "", integerTransformer);
const durationMinutes = useRouteQuery(
  "durationMinutes",
  "",
  integerTransformer,
);
const price = useRouteQuery("price", "", integerTransformer);

const validName = computed(() => {
  return name.value.length > 2;
});
const validDuration = computed(() => {
  return durationHours.value > 0 || durationMinutes.value > 0;
});
const validPrice = computed(() => {
  return price.value > 0;
});

const validForSubmit = computed(() => {
  return validName.value && validDuration.value && validPrice.value;
});

const { data: positionList, fetchData: getPositionList } = useFetch(
  api.positions.list,
);

const positionId = computed(() => {
  if (positionList.value?.length) {
    return positionList.value[0]._id;
  }
  return null;
});

const {
  data,
  isLoading,
  fetchData: createService,
} = useFetch(api.services.create, true);

const prices = ref([]);
function setPrices() {
  const priceTemplate = {
    positionId: positionId.value,
    positionName: positionList.value[0].name,
    prepaymentAmount: 0,
    price: price.value,
  };

  prices.value.push(priceTemplate);
}
async function submitForm() {
  try {
    setPrices();

    const totalDuration =
      (parseInt(durationHours.value * 60) || 0) +
      (parseInt(durationMinutes.value) || 0);
    const result = await createService([
      {
        ...serviceTemplate,
        name: name.value,
        duration: totalDuration,
      },
    ]);
    if (result) {
      router.push("/beauty/onboarding/success");
    }
  } catch (e) {
    console.error(e);
  } finally {
    prices.value = [];
  }
}
const serviceTemplate = {
  name: "",
  duration: 0,
  prices: prices.value,
  isActive: true,
  photo: {
    key: "",
    url: "",
  },
  onlineBooking: true,
  discountNewClient: null,
  groupEnabled: false,
  groupLimit: 10,
};

onMounted(async () => {
  await getPositionList();
});
</script>
<template>
  <div class="card mt-6">
    <div class="card-header">
      <div class="row">
        <div class="col-12 text-center">
          <h2>Створення послуги</h2>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="submitForm" action="#">
        <div class="row text-center">
          <p>
            Створіть послугу, яку буде виконувати співробітник, якого ви вказали
            на попередньому кроці
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <div class="max-width-300 w-100">
            <label>Назва послуги</label>
            <InputApp
              :valid="validName"
              :in-valid="!validName"
              class="w-100"
              v-model="name"
            ></InputApp>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="max-width-300 w-100 mt-3">
            <label>Тривалість</label>
            <div class="row">
              <div class="col">
                <InputApp
                  :valid="validDuration"
                  :in-valid="!validDuration"
                  type="number"
                  class="w-100"
                  placeholder="годин"
                  v-model="durationHours"
                ></InputApp>
              </div>
              <div class="col">
                <InputApp
                  :valid="validDuration"
                  :in-valid="!validDuration"
                  type="number"
                  class="w-100"
                  placeholder="хвилин"
                  v-model="durationMinutes"
                ></InputApp>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <div class="max-width-300 w-100">
            <label>Вартість (грн.)</label>
            <InputApp
              :valid="validPrice"
              :in-valid="!validPrice"
              class="w-100"
              type="number"
              v-model="price"
            ></InputApp>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <ButtonApp
            type="submit"
            :loading="isLoading"
            :disabled="!validForSubmit"
            class="btn-icon bg-gradient-primary"
          >
            Створити
          </ButtonApp>
        </div>
      </form>
    </div>
  </div>
</template>
