<template>
  <div class="container-fluid my-3 py-3 staff-page" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid my-3 py-3 staff-page" v-show="dataLoaded">
    <div class="row">
      <div class="card" id="basic-info">
        <div class="card-body pt-0 mx-1">
          <div class="row mt-3">
            <h3 class="heading mb-2">Налаштування знижок</h3>
            Створюйте знижки, які будуть автоматично застосовуватись при
            бронюванні.
            <h3 class="heading mt-3 mb-2">Знижки на тривале перебування</h3>
            <div class="col-lg-12">
              <div class="row">
                <div class="row">
                  Збільшуйте тривалість бронювань, надаючи знижки для гостей,
                  які бронюють декілька діб.
                </div>
                <div
                  class="col-lg-3 created-discount mt-1"
                  v-for="(discount, index) in discounts"
                  :key="index"
                >
                  <div class="discount-value">
                    Знижка {{ discount.amount }}%
                  </div>
                  <div class="discount-description">
                    За бронювання від {{ discount.thresholdDays }} діб
                  </div>
                  <div class="row">
                    <div class="col">
                      <button
                        data-bs-toggle="modal"
                        :data-bs-target="`#${deleteHotelDiscountModalParameters.modalId}`"
                        @click="removeDiscountPreHook(discount._id)"
                        class="btn btn-link text-dark text-decoration-underline mb-0 p-0"
                      >
                        видалити
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <router-link
                      class="btn mt-2 btn-sm btn-outline-secondary add-discount"
                      to="/settings/discounts/new"
                      role="button"
                    >
                      Додати знижку
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="deleteHotelDiscountModalParameters"></BmnCustomPopup>
</template>

<script>
import Spinner from "@/views/shared/Spinner.vue";

import api from "@/services/api";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";

export default {
  setup() {},

  name: "settings-discounts-hotel-longterms",

  components: {
    BmnCustomPopup,
    Spinner,
  },

  data() {
    return {
      dataLoaded: false,

      discounts: [],

      deleteHotelDiscountModalParameters: {
        modalId: "modal-delete-1",
        modalTitle: "Видалення знижки",
        modalDescription: "Ви впевнені, що хочете видалити знижку?",
        modalButtonConfirmText: "Видалити",
        modalButtonCancelText: "Відмінити",
        data: {
          discountId: null,
        },
        confirmHandler: async (data) => {
          await this.remove(data.discountId);
        },
      },
    };
  },

  async mounted() {
    this.discounts = await api.hotelDiscounts.list();

    this.dataLoaded = true;
  },

  methods: {
    async remove(id) {
      try {
        await api.hotelDiscounts.removeOne(id);
        this.discounts = await api.hotelDiscounts.list();

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    removeDiscountPreHook(id) {
      this.deleteHotelDiscountModalParameters.data.discountId = id;
    },
  },
};
</script>

<style scoped>
.underline-link {
  text-decoration: underline;
}
.created-discount {
  border: 1px solid #ccc; /* Тонкая серая рамка */
  border-radius: 8px; /* Скругленные углы */
  padding: 12px; /* Внутренние отступы */
  margin-bottom: 12px; /* Нижний отступ */
  margin-right: 12px; /* Нижний отступ */
  transition: background-color 0.3s ease;
}

.created-discount:hover {
  border-color: #deede3;
  background-color: #deede3;
}

.discount-value {
  color: black;
  font-size: 12pt;
}
.heading {
  padding-left: 0px !important;
  margin-bottom: 0px;
}
.add-discount {
  color: #0f4432;
}
.container-fluid {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
</style>
