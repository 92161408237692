<script setup>
import { onMounted, ref } from "vue";

import ButtonApp from "@/components/common/other/ButtonApp.vue";

import api from "@/services/api";

const emit = defineEmits(["nextStep"]);

function nextStep() {
  emit("nextStep");
}

const company = ref({});
const price = ref(0);

onMounted(async () => {
  company.value = await api.company.show();

  const crmMode = company.value.crmMode;
  const quantity = company.value.hardLimits.staffMax;

  switch (crmMode) {
    case "hotel":
      price.value = quantity * 250;
      if (quantity >= 8) {
        price.value = 2000;
      }
      break;
    case "general":
    default:
      price.value = quantity * 150;
      if (quantity >= 8) {
        price.value = 1250;
      }
      break;
  }
});
</script>
<template>
  <div>
    <div class="row">
      <div class="col-12 text-center">
        <h2>Вітаємо в bookmenow</h2>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
          <p>
            Дякуємо, що обрали нас! У вас є
            <strong>7 днів безкоштовного тестового періоду</strong>, щоб оцінити
            всі переваги нашого сервісу. Після завершення тестового періоду ви
            зможете продовжити користування, сплативши підписку вартістю
            <strong>{{ price }} грн на місяць</strong>.
          </p>
        </div>
        <div class="col-lg-2"></div>
      </div>
      <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
          <p class="noPadding">
            Декілька простих кроків, щоб почати роботу з bookmenow:
          </p>
          <ol>
            <li>
              <p>
                Підпишіться на наш Telegram канал. В ньому ми пишемо про всі
                нові функції які додаємо<br />
                <a
                  href="https://t.me/+PEJF51ogBChlNGIy"
                  style="text-decoration: underline"
                  target="_blank"
                  >Підписатись</a
                >
              </p>
            </li>
            <li>
              <p>
                Завантажте мобільний застосунок, з ним використовувати bookmenow
                ще приємніше. <br />
                <a
                  href="https://apps.apple.com/ua/app/bookmenow/id6444885822"
                  style="text-decoration: underline"
                  target="_blank"
                  >iOS застосунок</a
                >
                <br />
                <a
                  href="https://play.google.com/store/apps/details?id=com.app.bookmenow"
                  style="text-decoration: underline"
                  target="_blank"
                  >Android застосунок</a
                >
              </p>
            </li>
            <li>
              <p>
                Натисніть "Розпочати", щоб додати ваш перший обʼєкт розміщення
              </p>
            </li>
          </ol>
        </div>
        <div class="col-lg-2"></div>
      </div>
      <div class="row mt-3">
        <!-- <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
          <ButtonApp @click="nextStep" :class="'btn-icon bg-gradient-primary'">
            Розпочати
          </ButtonApp>
        </div>
        <div class="col-lg-4"></div> -->

        <!--  Розпочати -->
        <div class="fixed-bottom mb-2">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="nextStep"
                >
                  <span> Розпочати</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Розпочати фiнiш-->
      </div>
    </div>
  </div>
</template>
<style scoped>
.noPadding {
  margin-top: 8px;
}
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>
