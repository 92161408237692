<template>
  <div v-show="!dataLoaded" class="container-fluid my-3 py-3">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div v-show="dataLoaded" class="container-fluid mt-2">
    <div class="row mb-5">
      <div class="col-lg-10 mx-auto mt-lg-0">
        <div class="row">
          <div class="available">
            <div class="element">Доступність</div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="managerTemplate.isActive"
              />
            </div>
          </div>
        </div>
        <!-- Card Basic Info -->
        <div id="basic-info" class="card">
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
                <label class="form-label">Імʼя</label>
                <div class="input-group input-group-outline">
                  <input
                    v-model="managerTemplate.firstName"
                    class="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
                <label class="form-label">Прізвище</label>
                <div class="input-group input-group-outline">
                  <input
                    v-model="managerTemplate.lastName"
                    class="form-control"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
                <label class="form-label">Телефон</label>
                <div class="input-group input-group-outline">
                  <input
                    v-model="managerTemplate.phoneNumber"
                    v-mask="'+380#########'"
                    class="form-control"
                    maxlength="13"
                    minlength="13"
                    pattern="\+[0-9]{12}"
                    placeholder="+380"
                    type="tel"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
                <label class="form-label">Email</label>
                <div class="input-group input-group-outline">
                  <input
                    v-model="managerTemplate.email"
                    class="form-control"
                    type="email"
                  />
                </div>
              </div>
            </div>
            <div v-if="mode === 'create'" class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
                <div
                  :class="{ 'is-filled': managerTemplate.password1 }"
                  class="input-group input-group-outline"
                >
                  <label class="form-label">Пароль</label>
                  <input
                    v-model="managerTemplate.password1"
                    autocomplete="new-password"
                    class="form-control"
                    type="password"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
                <div
                  :class="{ 'is-filled': managerTemplate.password2 }"
                  class="input-group input-group-outline"
                >
                  <label class="form-label">Повторіть пароль</label>
                  <input
                    v-model="managerTemplate.password2"
                    class="form-control"
                    type="password"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Basic Info finish -->
        <!-- Change Password -->
        <div v-if="showChangePassword()" id="password" class="card mt-2">
          <div class="card-body">
            <h3>Змінити пароль до адмін панелі</h3>
            <div class="row">
              <div class="col-lg-6 mt-1">
                <label class="form-label">Новий пароль</label>
                <div class="input-group input-group-outline">
                  <input
                    v-model="newPassword"
                    autocomplete="new-password"
                    class="form-control"
                    type="password"
                  />
                </div>
              </div>
              <div class="col-lg-6 mt-1">
                <label class="form-label">Підтвердити новий пароль</label>
                <div class="input-group input-group-outline">
                  <input
                    v-model="newPasswordConfirm"
                    autocomplete="new-password"
                    class="form-control"
                    type="password"
                  />
                </div>
              </div>
            </div>
            <button
              :disabled="!isValidPassword()"
              class="btn btn-outline-secondary btn-sm mt-2 float-end mb-0"
              @click="changePassword"
            >
              Змінити пароль
            </button>
          </div>
        </div>
        <!-- Change Password finish -->

        <!-- Зарплатня -->
        <div v-if="showDevFeatures" id="salary" class="card mt-4">
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-body">
            <h6 class="p-0 mt-0">Зарплатня</h6>
            <div class="row">
              <div class="col">На балансі співробітника:</div>
              <div class="col">
                <label class="text-danger">1732 грн </label>
              </div>
              <div class="col">
                <router-link class="" to="/team/salaryinfostaff/">
                  <i class="fas fa-eye text-secondary"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Зарплатня finish -->

        <!-- Card Удалить менеджера -->
        <div v-if="this.managerTemplate._id" id="delete" class="card mt-2">
          <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div class="w-50">
                <h3>Видалити</h3>
              </div>
              <div class="w-50 text-end">
                <!-- <button
                  class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto"
                  type="button"
                  name="button"
                >
                  Восстановить
                </button> -->
                <button
                  :data-bs-target="`#${modalParamsRemoveManager.modalId}`"
                  class="btn bg-gradient-danger mb-0 ms-2"
                  data-bs-toggle="modal"
                  name="button"
                  type="button"
                >
                  Видалити
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Удалить менеджера finish -->

        <!--  Зберегти -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  :disabled="!preSaveValidation()"
                  class="block btn bg-gradient-primary"
                  type="button"
                  @click="save"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти finish-->
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="modalParamsRemoveManager"></BmnCustomPopup>
</template>
<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.container-fluid {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.card {
  border-radius: 0px;
}
</style>

<script>
import api from "../../../../services/api";
import checkUserRole from "@/services/checkUserRole";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";
import Spinner from "@/views/shared/Spinner.vue";
import { mask } from "vue-the-mask";
import allowedShowDevFeatures from "@/services/allowedShowDevFeatures";

export default {
  name: "settings-manager",
  directives: { mask },
  components: {
    BmnCustomPopup,
    Spinner,
  },

  async beforeMount() {
    this.showDevFeatures = allowedShowDevFeatures();
    if (this.$route.params.id) {
      this.mode = "edit";
      this.managerTemplate = await api.managers.findOne(this.$route.params.id);
    } else {
      this.mode = "create";
    }

    this.dataLoaded = true;
  },

  data() {
    return {
      dataLoaded: false,
      showDevFeatures: false,

      mode: "", // create | edit

      managerTemplate: {
        name: "",
        isActive: true,
      },

      newPassword: "",
      newPasswordConfirm: "",

      modalParamsRemoveManager: {
        modalId: "modal-default-1",
        modalTitle: "Видалення",
        modalDescription:
          "Ви впевнені, що хочете видалити адміністратора? Після видалення, адміністратора відновити неможливо.",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Видалити",
        data: {},
        confirmHandler: async () => {
          try {
            await api.managers.removeOne(this.$route.params.id);
            this.$store.commit("addToast", {
              title: "Збережено",
            });
            await this.$router.push("/team/managers");
          } catch (error) {
            this.$store.commit("addToast", {
              title: "Виникла помилка. Спробуйте ще раз",
              type: "error",
            });
          }
        },
      },
    };
  },

  methods: {
    async save() {
      const payload = {
        firstName: this.managerTemplate.firstName,
        lastName: this.managerTemplate.lastName,
        phoneNumber: this.managerTemplate.phoneNumber,
        email: this.managerTemplate.email,
        password: this.managerTemplate.password1,
        isActive: this.managerTemplate.isActive,
      };

      try {
        if (this.mode === "create") {
          await api.managers.create(payload);
        } else if (this.mode === "edit") {
          await api.managers.updateOne(this.$route.params.id, payload);
        }

        this.$store.commit("addToast", {
          title: "Збережено",
        });

        await this.$router.push("/team/managers");
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    showChangePassword() {
      return (
        this.mode !== "create" &&
        (checkUserRole("owner") || checkUserRole("root"))
      );
    },

    isValidPassword() {
      if (this.newPassword && this.newPassword === this.newPasswordConfirm) {
        return true;
      }

      return false;
    },

    async changePassword() {
      try {
        await api.user.resetPassword(this.$route.params.id, {
          password: this.newPassword,
          self: false,
        });

        this.$store.commit("addToast", {
          title: "Пароль змінено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    preSaveValidation() {
      let isValidPassword =
        this.managerTemplate.password1 &&
        this.managerTemplate.password2 &&
        this.managerTemplate.password1 === this.managerTemplate.password1;
      if (this.mode !== "create") {
        isValidPassword = true;
      }

      return (
        this.managerTemplate.firstName &&
        this.managerTemplate.phoneNumber &&
        isValidPassword
      );
    },
  },
};
</script>
<style scoped>
.available {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-end;
  padding-right: 16px;
}
.element {
  margin-right: 10px;
}
</style>
