<script setup>
import { computed, ref } from "vue";
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import SelectApp from "@/components/common/SelectApp.vue";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: true,
  },
  nameField: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["nextStep"]);

const options = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
const selectedOption = ref(props.modelValue);

const isValidGuests = computed(() => {
  return !!selectedOption.value;
});

function nextStep() {
  emit("nextStep", selectedOption.value, props.nameField);
}
</script>
<template>
  <div class="">
    <div class="row">
      <div class="col-12 text-center">
        <h2>Максимальна кількість гостей</h2>
      </div>
    </div>

    <div class="card-body pt-0 mb-5">
      <div class="d-flex justify-content-center">
        <div class="input-group input-group-outline max-width-300">
          <SelectApp
            v-model="selectedOption"
            :settings="{
              options: options,
              showPointer: false,
              maxHeight: 200,
            }"
          />
        </div>
      </div>
      <!-- <div class="row mt-5">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
          <ButtonApp
            :disabled="!isValidGuests"
            @click="nextStep"
            :class="'btn-icon bg-gradient-primary'"
          >
            Продовжити
          </ButtonApp>
        </div>
        <div class="col-lg-4"></div>
      </div> -->

      <!--  Продовжити -->
      <div class="fixed-bottom mb-2">
        <div class="row">
          <div class="col-10"></div>
          <div class="col-lg-2">
            <div class="mx-4">
              <button
                type="button"
                class="block btn bg-gradient-primary"
                :disabled="!isValidGuests"
                @click="nextStep"
              >
                <span> Продовжити</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--  Продовжити фiнiш-->
    </div>
  </div>
</template>
<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>
