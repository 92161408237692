<template>
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col-lg-12 mx-auto">
        <div class="row">
          <h2>SPA модуль від bookmenow</h2>
          <div class="">
            Модуль для SPA це інструмент кервання вашою банею, чаном (будь чим
            що пропонується гостям погодинно) в звичному вам інтерфейсі.
            <br />
            <!-- Кожен користувач адмін панелі може налаштувати які саме сповіщення він хоче отримувати від бота. Ці налаштування можна виконати в <a href="/team/editprofile" class="text-primary">Профілі користувача</a> -->
            <br />
            <h3 class="">Як виглядає</h3>
            Оскільки СПА послуги бронюються погодинно - ми розробили інший
            вигляд календаря, на якому видно на який час заброньовано
            послугу.<br />
            На бронюванні ви побачите звичні позначки:
            <ol>
              <li class="mb-2">Час початку і час закінчення послуги</li>
              <li class="mb-2">Імʼя клієнта і його контакнта інформація</li>
              <li class="mb-2">
                Вартість бронювання, сума оплати, сума залишку оплати
              </li>
            </ol>
            <div class="card">
              <div class="card-body desktop">
                <img
                  src="../../../src/assets/img/spa-exampleDesktop.png"
                  alt=""
                  style="width: 100%"
                />
              </div>
            </div>
            <div class="card">
              <div class="card-body mobile">
                <img
                  src="../../../src/assets/img/spa-mobile.png"
                  alt=""
                  style="width: 100%"
                />
              </div>
            </div>

            <div>
              <h3>Як влаштовані доступи до модулю</h3>
              Ви можете додавати будь-яку кількість Адміністраторів саме в
              модулі СПА, тож персонал який працює лише із СПА не буде бачити
              нічого, що стосується Готелю. <br />
              І навпаки: якщо вам необхідно, щоб Адміністратор мав доступ до
              обох модулів (Готель і СПА), це також легко реалізуємо.
              <h3>Чи є онлайн бронювання і оплата</h3>
              Так! Як ми і казали - зручний і знайомий процес ми створили і для
              СПА модулю. <br />
              Тож ваши клієнта за посиланням зможуть:
              <ol>
                <li class="mb-2">Обрати ресурс</li>
                <li class="mb-2">Обрати вільний час Ресурсу</li>
                <li class="mb-2">Ввести контакнті дані</li>
                <li class="mb-2">Сплатити</li>
                <li class="mb-2">
                  А ви, як завжди, отримаєте повідомлення від Телеграм боту про
                  нове бронювання, але тепер в СПА!
                </li>
              </ol>

              <h3>Яка вартість</h3>
              Вартість залежить від кількості ресурсів (кількість бань, чанів,
              тощо), які ви додасте в СПА модуль.<br />
              Вартість керування 1 ресурсом - <b>150 грн/міс.</b>
              <h3>Відео екскурсія по СПА модулю</h3>
              незабаром тут зʼявиться відео...
              <h3>Як розпочати</h3>
              Щоб підключити СПА модуль до вашого акаунту - зверніться, будь
              ласка до вашого Менеджера.
              <br />
            </div>
            <!-- <img
                  src="../../../assets/img/gif/telegram-notification.gif"
                  alt=""
                  style="width: 100%"
                /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="col">
          <div class="card mt-5">
            <div class="card-body p-3 pt-0">
              <div class="row">
                <div class="col-4">
                  <img
                    src="../../assets/img/support.jpeg"
                    alt="kal"
                    class="border-radius-lg shadow shadow-dark w-100 mt-n4"
                  />
                </div>
                <div class="col-8 my-auto">
                  <p class="text-muted text-sm mt-3">
                    Вітаю! Мене звати Сергій. І я із задоволенням допоможу вам з
                    усіма питаннями.
                  </p>
                  <a
                    href="https://t.me/bookmenowsupport"
                    target="_blank"
                    class="btn btn-sm bg-gradient-dark mb-0"
                    >Написати</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.desktop {
  @media (max-width: 991px) {
    display: none;
  }
}
.mobile {
  @media (min-width: 991px) {
    display: none;
  }
}
.container-fluid {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
</style>

<script></script>
