<template>
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-4 mb-lg-0 mb-4">
            <div class="card shadow-lg">
              <div class="card-header text-center pt-4 pb-3">
                <h1 class="font-weight-bold mt-2">
                  <small class="text-lg align-top me-1"></small>228<small
                    class="text-lg"
                    >грн / 200 СМС</small
                  >
                </h1>
                <span class="ps-3">1,14 грн / 1 смс</span>
              </div>

              <div class="card-body text-lg-start text-center pt-0">
                <!-- <div
                  class="d-flex justify-content-lg-start justify-content-center p-2"
                >
                  <i class="material-icons my-auto">done</i>
                  <span class="ps-3">Автоматичні нагадування</span>
                </div>
                <div
                  class="d-flex justify-content-lg-start justify-content-center p-2"
                >
                  <i class="material-icons my-auto">done</i>
                  <span class="ps-3"
                    >Підтвердження після бронювання в адмін панелі</span
                  >
                </div>
                <div
                  class="d-flex justify-content-lg-start justify-content-center p-2"
                >
                  <i class="material-icons my-auto">done</i>
                  <span class="ps-3"
                    >Підтвердження після бронювання в онлайн</span
                  >
                </div> -->
                <a
                  href="javascript:;"
                  class="btn btn-icon bg-gradient-dark d-lg-block mt-3 mb-0"
                  @click="topUp(228)"
                >
                  Поповнити
                  <i class="fas fa-arrow-right ms-1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-lg-0 mb-4">
            <div class="card bg-gradient-dark shadow-lg">
              <div class="card-header text-center pt-4 pb-3 bg-transparent">
                <h1 class="font-weight-bold mt-2 text-white">
                  <small class="text-lg align-top me-1"></small>1140<small
                    class="text-lg"
                    >грн / 1000 СМС</small
                  >
                </h1>
                <span class="ps-3">1,14 грн / 1 смс</span>
              </div>
              <div class="card-body text-lg-start text-center pt-0">
                <!-- <div
                  class="d-flex justify-content-lg-start justify-content-center p-2"
                >
                  <i class="material-icons my-auto text-white">done</i>
                  <span class="ps-3 text-white">Автоматичні нагадування</span>
                </div>
                <div
                  class="d-flex justify-content-lg-start justify-content-center p-2"
                >
                  <i class="material-icons my-auto text-white">done</i>
                  <span class="ps-3 text-white"
                    >Підтвердження після бронювання в адмін панелі
                  </span>
                </div>
                <div
                  class="d-flex justify-content-lg-start justify-content-center p-2"
                >
                  <i class="material-icons my-auto text-white">done</i>
                  <span class="ps-3 text-white"
                    >Підтвердження після бронювання в онлайн</span
                  >
                </div> -->
                <a
                  href="javascript:;"
                  class="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0"
                  @click="topUp(1140)"
                >
                  Поповнити
                  <i class="fas fa-arrow-right ms-1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-lg-0 mb-4">
            <div class="card shadow-lg">
              <div class="card-header text-center pt-4 pb-3">
                <h1 class="font-weight-bold mt-2">
                  <small class="text-lg align-top me-1"></small>2280<small
                    class="text-lg"
                    >грн / 2000 СМС</small
                  >
                </h1>
                <span class="ps-3">1,14 грн / 1 смс</span>
              </div>
              <div class="card-body text-lg-start text-center pt-0">
                <!-- <div
                  class="d-flex justify-content-lg-start justify-content-center p-2"
                >
                  <i class="material-icons my-auto">done</i>
                  <span class="ps-3">Автоматичні нагадування</span>
                </div>
                <div
                  class="d-flex justify-content-lg-start justify-content-center p-2"
                >
                  <i class="material-icons my-auto">done</i>
                  <span class="ps-3"
                    >Підтвердження після бронювання в адмін панелі</span
                  >
                </div>
                <div
                  class="d-flex justify-content-lg-start justify-content-center p-2"
                >
                  <i class="material-icons my-auto">done</i>
                  <span class="ps-3"
                    >Підтвердження після бронювання в онлайн</span
                  >
                </div> -->
                <a
                  href="javascript:;"
                  class="btn btn-icon bg-gradient-dark d-lg-block mt-3 mb-0"
                  @click="topUp(2280)"
                >
                  Поповнити
                  <i class="fas fa-arrow-right ms-1"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="card mt-2">
            <div class="card-header">
              <h5 class="mb-0">Історія поповнень</h5>
            </div>
            <div class="body">
              <!-- TODO: remove or repurpose -->
              <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
                <Spinner :loaded="dataLoaded"></Spinner>
              </div>
              <!-- <div class="table-responsive">
                <table class="table table-hover" id="datatable-search">
                  <thead class="thead-light text-center">
                    <tr>
                      <th>Дата</th>
                      <th>Сума</th>
                      <th>Кількість кредітсів</th>
                      <th>Статус</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-xs font-weight-normal text-center">
                        <span class="my-2 text-xs">03.05.2022</span>
                      </td>
                      <td class="text-xs font-weight-normal text-center">
                        <span class="my-2 text-xs">650 грн</span>
                      </td>
                      <td class="text-xs font-weight-normal text-center">
                        <span class="my-2 text-xs">100</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-xs font-weight-normal text-center">
                        <span class="my-2 text-xs">01.05.2022</span>
                      </td>
                      <td class="text-xs font-weight-normal text-center">
                        <span class="my-2 text-xs">1500 грн</span>
                      </td>
                      <td class="text-xs font-weight-normal text-center">
                        <span class="my-2 text-xs">200</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
              <div class="tab-pane mb-2 mx-2">
                <div class="table-responsive">
                  <vue-good-table
                    mode="remote"
                    :pagination-options="{
                      enabled: true,
                      perPage: tablePerPage,
                      position: 'bottom',
                      perPageDropdownEnabled: false,

                      nextLabel: 'наст.',
                      prevLabel: 'попер.',
                      rowsPerPageLabel: 'записів на сторінці',
                      ofLabel: 'із',
                      pageLabel: 'сторінка',
                      allLabel: 'всі',
                    }"
                    :totalRows="tableData.totalRecords"
                    :columns="datatableColumns"
                    :rows="tableData.rows"
                    v-model:isLoading="isLoading"
                    v-on:page-change="onInstantMessageTopUpPageChange"
                    theme="default"
                    styleClass="vgt-table"
                    responsive
                    compactMode
                  >
                    <template #table-row="props">
                      <div v-if="props.column.field === 'date'">
                        <span class="my-2 text-xs">
                          {{ timestampToString(props.row.date) }}
                        </span>
                      </div>

                      <div
                        v-if="props.column.field === 'monobankInvoice.pageUrl'"
                      >
                        <a
                          :href="props.row.monobankInvoice?.pageUrl"
                          class="my-2 text-xs"
                          target="_blank"
                        >
                          {{ props.row?.monobankInvoice?.pageUrl }}
                        </a>
                        <i
                          v-if="props.row?.monobankInvoice?.pageUrl"
                          class="material-icons text-lg me-2 cursor-pointer"
                          @click="
                            copyToClipboard(props.row.monobankInvoice.pageUrl)
                          "
                          >copy</i
                        >
                      </div>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/views/shared/Spinner.vue";

import api from "@/services/api";
import constants from "@/constants";
import { DateTime } from "luxon";
import useClipboard from "vue-clipboard3";
import allowedShowDevFeatures from "@/services/allowedShowDevFeatures";

export default {
  name: "settings-sms-payment",

  components: {
    Spinner,
  },

  data() {
    return {
      dataLoaded: false,
      showDevFeatures: false,
      tablePerPage: constants.defaultDatatablePerPage,
      tableData: {
        totalRecords: 0,
        rows: [],
      },
      isLoading: false,
      serverParams: {
        sort: [
          {
            field: "date",
            type: "desc",
          },
        ],
        page: 1,
        perPage: constants.defaultDatatablePerPage,
      },
      datatableColumns: [
        {
          label: "Дата",
          field: "date",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Сума",
          field: "amount",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Кількість кредітсів",
          field: "credits",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Статус",
          field: "status",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Посилання",
          field: "monobankInvoice.pageUrl",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
      ],
    };
  },

  created() {
    this.showDevFeatures = allowedShowDevFeatures();
  },

  async mounted() {
    await this.loadItems();

    this.dataLoaded = true;
  },

  methods: {
    async loadItems() {
      const result = await api.company.getInstantMessageTopUps({
        limit: this.serverParams.perPage,
        offset: (this.serverParams.page - 1) * this.serverParams.perPage,
        datatable: "y",
      });

      this.tableData.totalRecords = result.totalRecords;
      this.tableData.rows = result.rows;
    },

    async topUp(amount) {
      const result = await api.company.instantMessageTopUp({
        amount,
        locationOrigin: window.location.origin,
      });

      if (!result || !result.pageUrl) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
        return;
      }

      open(result.pageUrl, "_self");
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    async onInstantMessageTopUpPageChange(params) {
      this.updateParams({ page: params.currentPage });
      await this.loadItems();
    },

    timestampToString(input) {
      if (!input) {
        return "-";
      }
      const datetime = DateTime.fromISO(input);
      return datetime.setLocale("uk-UA").toFormat("dd.MM.yyyy HH:mm");
    },

    async copyToClipboard(value) {
      const { toClipboard } = useClipboard();
      try {
        await toClipboard(value);
        this.$store.commit("addToast", {
          title: "Скопійовано",
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.container-fluid {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
</style>
