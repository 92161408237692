<template>
  <div class="container-fluid" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mt-3">
      <div class="col-12">
        <router-link
          to="/settings/staff/new"
          class="btn btn-primary"
          role="button"
          style="margin-left: 16px"
          v-show="addNewIsAllowed"
          v-if="company.crmMode !== 'hotel'"
        >
          + {{ getLabelAddNew() }}
        </router-link>
        <router-link
          to="/settings/staff/new"
          class="btn btn-primary"
          role="button"
          style="margin-left: 16px"
          v-show="addNewIsAllowed"
          v-if="company.crmMode === 'hotel'"
        >
          + Номер
        </router-link>
        <button
          class="btn btn-primary"
          role="button"
          v-show="!addNewIsAllowed"
          @click="showWarning = !showWarning"
          style="opacity: 0.5"
          v-if="company.crmMode !== 'hotel'"
        >
          + {{ getLabelAddNew() }}
        </button>
        <button
          class="btn btn-primary"
          role="button"
          v-show="!addNewIsAllowed"
          @click="showWarning = !showWarning"
          style="opacity: 0.5"
          v-if="company.crmMode === 'hotel'"
        >
          + номер
        </button>
        <div class="card mt-5" v-if="!addNewIsAllowed && showWarning">
          <div class="card-body p-3 pt-0">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-4">
                  <img
                    src="../../../../assets/img/support.jpeg"
                    alt="kal"
                    class="border-radius-lg shadow shadow-dark w-100 mt-n4"
                  />
                </div>
                <div class="col-lg-8 my-auto">
                  <p
                    class="text-muted text-sm mt-3"
                    v-if="company.crmMode === 'hotel'"
                  >
                    Шановний клієнте! Ваш поточний тариф не передбачає додавання
                    більшої кількості обʼєктів. Щоб підвищити тариф, зверніться,
                    будь ласка, до менеджера👇
                  </p>
                  <p
                    class="text-muted text-sm mt-3"
                    v-if="company.crmMode !== 'hotel'"
                  >
                    Шановний клієнте! Ваш поточний тариф не передбачає додавання
                    більшої кількості Майстрів. Щоб підвищити тариф, зверніться,
                    будь ласка, до менеджера👇
                  </p>
                  <a
                    href="https://t.me/bookmenowsupport"
                    target="_blank"
                    class="btn btn-sm bg-gradient-dark mb-0"
                    >Написати в підтримку</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card mt-3"
          v-for="(position, index) in positions"
          :key="index"
        >
          <div class="card-header">
            <h6 class="mb-0">
              {{ getLabelAll() }} - {{ position.name }}
              <span v-if="position.isRemoved">[КАТЕГОРІЯ ВИДАЛЕНА]</span>
            </h6>
            <p class="text-sm mb-0"></p>
          </div>

          <div class="vgt-responsive">
            <table class="custom-table vgt-table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    style="width: 50px"
                  ></th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 thead th"
                    aria-controls="col-1"
                    style="width: 100px"
                    v-if="company.crmMode !== 'hotel'"
                  >
                    <span>Ім`я</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 thead th"
                    aria-controls="col-1"
                    style="width: 200px"
                    v-if="company.crmMode === 'hotel'"
                  >
                    <span>Назва</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: 50px; width: auto"
                    v-if="company.crmMode === 'hotel'"
                  >
                    <span>Кількість гостей</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    v-if="company.crmMode !== 'hotel'"
                    style="min-width: auto; width: auto"
                  >
                    <span>ФОТО</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                    v-if="company.crmMode !== 'hotel'"
                  >
                    <span>ТЕЛЕФОН</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                    v-if="company.crmMode !== 'hotel'"
                  >
                    <span>ПОСАДА</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>ДОСТУПНІСТЬ</span>
                  </th>
                </tr>
              </thead>

              <draggable
                @update="onEnd"
                v-model="position.children"
                v-bind="dragOptions"
                handle=".handle"
                tag="tbody"
                item-key="name"
                :id="`category-${index}`"
              >
                <template #item="{ element }">
                  <tr @click="routeToStaffItem(element._id)" class="">
                    <td>
                      <div class="text-center disableCopyElement">
                        <i class="material-icons text-lg me-2 grab-box handle">
                          drag_indicator</i
                        >
                      </div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <span>{{ element.name }}</span>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                      v-if="company.crmMode === 'hotel'"
                    >
                      <span>{{ element?.checkinRules.guestsMax }}</span>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                      v-if="company.crmMode !== 'hotel'"
                    >
                      <div class="max-width-100">
                        <img
                          :src="element.photo.url"
                          class="img-thumbnail"
                          alt=""
                        />
                      </div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                      v-if="company.crmMode !== 'hotel'"
                    >
                      <div>{{ element.phoneNumber }}</div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                      v-if="company.crmMode !== 'hotel'"
                    >
                      <div>
                        <span data-v-5d5a7bea="">{{
                          element.positionId.name
                        }}</span>
                      </div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <div class="mx-1 badge badge-dot">
                        <div v-if="element.isActive">
                          <i class="bg-success"></i>
                          <span class="text-dark text-xs">Увімкнений</span>
                        </div>
                        <div v-if="!element.isActive">
                          <i class="bg-danger"></i>
                          <span class="text-dark text-xs">Вимкнений</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </draggable>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import allowedShowDevFeatures from "@/services/allowedShowDevFeatures";

const _ = require("lodash");
import { ref } from "vue";

import Spinner from "@/views/shared/Spinner.vue";

import api from "../../../../services/api";
import constants from "@/constants";
import { useTable } from "@/composables/good-table";
import draggable from "vuedraggable";

export default {
  setup() {
    const { loadItems, tableData, isLoading } = useTable(api.staff.list);

    return {
      loadItems,
      isLoading,
      tableData,

      positions: ref([]),
    };
  },

  name: "team-staff",
  components: {
    Spinner,
    draggable,
  },

  beforeMount() {
    this.showDevFeatures = allowedShowDevFeatures();
  },

  async mounted() {
    this.company = await api.company.show();
    setTimeout(async () => {
      await this.loadItems();

      const filterIncludeRemoved = {
        includeRemoved: "1",
      };
      const positions = await api.positions.list(filterIncludeRemoved);

      const positionsMap = _.keyBy(positions, "_id");

      this.tableData.rows.forEach((singleStaff) => {
        const positionId = singleStaff.positionId._id;

        positionsMap[positionId].children =
          positionsMap[positionId]?.children ?? [];

        positionsMap[positionId].children.push(singleStaff);
      });

      this.positions = Object.values(positionsMap);
      this.positions = this.positions.sort((a, b) => {
        return a.rank - b.rank;
      });
      this.positions.forEach((position) => {
        if (!position.children) {
          position.children = [];
        }
      });

      this.positions = this.positions.filter((position) => {
        return !position.isRemoved;
      });

      this.dataLoaded = true;
    }, 500);
  },

  data() {
    return {
      showDevFeatures: false,
      company: {
        crmMode: null,
        localizationMode: null,
      },
      showWarning: false,
      dataLoaded: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 500,
        disabled: false,
        ghostClass: "ghost",
        group: {
          name: "items",
          pull: "clone",
          put: false,
        },
      };
    },

    addNewIsAllowed() {
      const staffLimitMax = this.company.hardLimits?.staffMax || 1;
      const staffQuantity = this.tableData.rows.length;

      return staffQuantity < staffLimitMax;
    },
  },
  methods: {
    async remove(id) {
      await api.staff.removeOne(id);
    },

    async toggleIsActive(staff) {
      const payload = {
        isActive: staff.isActive,
      };
      await api.staff.updateOne(staff._id, payload);
    },
    routeToStaffItem(id) {
      this.$router.push(`/settings/staff/${id}`);
    },

    async onEnd() {
      let sortIndex = 0;
      const newOrder = [];

      this.positions.forEach((position) => {
        position?.children.forEach((singleStaff) => {
          sortIndex += 1;
          newOrder.push({
            _id: singleStaff._id,
            name: singleStaff.name,
            rank: sortIndex,
          });
        });
      });

      try {
        await api.staff.sort(newOrder);
        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    getLabelAddNew() {
      const localizationMode = this.company.localizationMode;

      switch (true) {
        case localizationMode === "spa":
        case localizationMode === "hotel":
          return "Ресурс";
        default:
          return "Майстер";
      }
    },
    getLabelAll() {
      const localizationMode = this.company.localizationMode;

      switch (true) {
        case localizationMode === "spa":
          return "Ресурси";
        case localizationMode === "hotel":
          return "Номери";
        default:
          return "Майстри";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.disableCopyElement {
  -webkit-touch-callout: none; /* Отключение контекстного меню iOS */
  -webkit-user-select: none; /* Отключение выбора текста в Safari */
  -khtml-user-select: none; /* Отключение выбора текста в Konqueror HTML */
  -moz-user-select: none; /* Отключение выбора текста в Firefox */
  -ms-user-select: none; /* Отключение выбора текста в Internet Explorer/Edge */
  user-select: none; /* Отключение выбора текста в большинстве современных браузеров */
}

table.vgt-table td {
  vertical-align: middle;
}

.img-thumbnail {
  padding: 0;
  border: none;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

tr {
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background: #fbfbfb;
  }
}

.grab-box {
  cursor: grab;
}

.ghost {
  opacity: 0.7;
  background: #d7d6d6;
}
.container-fluid {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.card {
  border-radius: 0px;
}
</style>
