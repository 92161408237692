<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mt-3">
      <div class="col-lg-12 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="channgelManagerAllignment">
                <div class="element">
                  <h3>Допомога</h3>
                </div>
              </div>
              <hr />
              <div class="mt-3" v-if="company.crmMode === 'hotel'">
                <h4>Новини</h4>
                Щоб бути в курсі всіх оновлень та новин bookmenow, підпишіться
                на наш
                <a
                  href="https://t.me/+PEJF51ogBChlNGIy"
                  target="_blank"
                  style="text-decoration: underline"
                  >Telegram канал</a
                >
              </div>
              <div class="mt-3" v-if="company.crmMode !== 'hotel'">
                <h4>Новини</h4>
                Щоб бути в курсі всіх оновлень та новин bookmenow, підпишіться
                на наш
                <a
                  href="https://t.me/+L04d2DTJkWwyNTli"
                  target="_blank"
                  style="text-decoration: underline"
                  >Telegram канал</a
                >
              </div>
              <div v-if="company.crmMode === 'hotel'">
                <h4>База знань</h4>
                Відповідь на найрозповсюдженіші запитання ви можете знайти в
                <a
                  href="https://bookmenow.pro/hotels/kb"
                  target="_blank"
                  style="text-decoration: underline"
                  >Базі знань</a
                >
              </div>
              <div class="mt-3" v-if="company.crmMode !== 'hotel'">
                <h4>База знань</h4>
                Відповідь на найрозповсюдженіші запитання ви можете знайти в
                <a
                  href="https://bookmenow.pro/kb"
                  target="_blank"
                  style="text-decoration: underline"
                  >Базі знань</a
                >
              </div>
              <div class="mt-3">
                <h4>Технічна підтримка</h4>
                Щоб отримати технічну підтримку, зверніться до нашого менеджера:
                <a
                  href="https://t.me/bookmenowsupport"
                  target="_blank"
                  style="text-decoration: underline"
                  >Написати</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import Spinner from "@/views/shared/Spinner.vue";
import allowedShowDevFeatures from "@/services/allowedShowDevFeatures";
// import SmallLoader from "@/views/shared/SmallLoader.vue";

export default {
  name: "channel-manager",

  async mounted() {
    this.company = await api.company.show();
    this.showDevFeatures = allowedShowDevFeatures();

    this.dataLoaded = true;
  },

  data() {
    return {
      showDevFeatures: false,
      dataLoaded: false,
      isLoading: false,
      company: {
        crmMode: "",
        isChannelManagerEnabled: false,
      },
    };
  },

  components: {
    // SmallLoader,
    Spinner,
  },

  methods: {
    async save() {
      if (!this.dataLoaded || !this.showDevFeatures) {
        return;
      }

      this.isLoading = true;

      try {
        await api.company.update({
          isChannelManagerEnabled: this.company.isChannelManagerEnabled,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.container-fluid {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.element {
  margin-top: 8px;
  margin-right: 20px;
}
.channgelManagerAllignment {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-start;
  padding-right: 16px;
}
</style>
