<template>
  <div class="wrapper-page-analytics container-fluid py-4" v-show="isLoading">
    <Spinner :loaded="!isLoading"></Spinner>
  </div>
  <div class="wrapper-page-analytics container-fluid py-4" v-show="!isLoading">
    <div
      class="row infoBlock"
      v-if="company.crmMode === 'hotel'"
      style="display: inline-block"
    >
      <div class="d-flex align-items-center gap-2">
        <span class="material-symbols-outlined"> info </span>
        <label class="text-xs" style="color: #458466">
          В разі якщо бронювання починається в одному місяці, а закінчується в
          наступному, то в аналітиці воно буде враховано в тому місяці, в якому
          воно закінчується
        </label>
      </div>
    </div>
    <div class="row">Дати відпочинку</div>

    <div class="row">
      <div class="col">
        <div class="dropdown d-inline">
          <a
            href="javascript:;"
            class="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            id="navbarDropdownMenuLink2"
          >
            🗓 {{ displaySelectedDateRange() }}
          </a>
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            data-popper-placement="left-start"
          >
            <li v-for="(dateRange, index) in listDateRanges" :key="index">
              <button
                class="dropdown-item border-radius-md"
                @click="filterByDateRange(dateRange._id)"
              >
                {{ dateRange.name }}
              </button>
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="dropdown d-inline">
          <a
            href="javascript:;"
            class="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            id="navbarDropdownMenuLink2"
          >
            {{ displaySelectedPosition() }}
          </a>
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            data-popper-placement="left-start"
          >
            <li v-for="(position, index) in listPositions" :key="index">
              <button
                class="dropdown-item border-radius-md"
                @click="filterByPositionId(position._id)"
              >
                {{ position.name }}
              </button>
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="dropdown d-inline">
          <a
            href="javascript:;"
            class="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            id="navbarDropdownMenuLink2"
          >
            {{ displaySelectedStaff() }}
          </a>
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            data-popper-placement="left-start"
          >
            <li v-for="(singleStaff, index) in listStaff" :key="index">
              <button
                class="dropdown-item border-radius-md"
                :class="{ 'bmn-staff-removed': singleStaff.isRemoved }"
                href="javascript:;"
                @click="filterByStaffId(singleStaff._id)"
              >
                {{ singleStaff.name }}
                <span v-if="singleStaff.positionId"
                  >({{ singleStaff.positionId.name }})</span
                >
                <span v-if="singleStaff._id && !singleStaff.isActive">
                  ❌
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr />
    <div class="row"></div>
    <div class="row" v-show="selectedDateRange === 'custom'">
      <div class="col-lg-3 col-sm-12 text-end">
        <flat-pickr
          v-model="customSelectedDateRange"
          id="calendarFlatpickr"
          class="form-control datetimepicker"
          :config="calendarConfig"
          ref="datePickerWrap"
        ></flat-pickr>
        <div class="mb-4"></div>
      </div>
    </div>
    <div class="row">
      <h5>
        Гроші
        <div class="badge badge-info badge-sm mx-3">
          {{ computedSelectedDateRange }}
        </div>
      </h5>
    </div>
    <div class="row mt-3">
      <div class="row mb-3">
        <div class="row mt-lg-3">
          <div
            class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-3"
            v-for="(value, _, i) in metrics"
            v-bind:key="i"
          >
            <div class="card mb-2">
              <div class="card-header p-3 pt-2">
                <div
                  class="icon icon-md icon-shape bg-gradient-success shadow-secondary shadow text-center border-radius-xl mt-n4 position-absolute"
                >
                  <i class="material-icons opacity-10">payment</i>
                </div>
                <div class="text-end pt-1">
                  <p class="text-sm mb-0">{{ value.label }}</p>
                  <h4 class="mb-0">{{ value.value }} грн</h4>
                </div>
              </div>
              <hr class="dark horizontal my-0" />
              <div class="card-footer p-3">
                <p class="text-xs">
                  {{ value.tooltip }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <h5>
          Відмінені бронювання
          <div class="badge badge-info badge-sm mx-3">
            {{ computedSelectedDateRange }}
          </div>
        </h5>
      </div>
      <div class="row mb-3">
        <div class="row mt-lg-3">
          <div
            class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-3"
            v-for="(value, k, i) in metricsCancelled"
            v-bind:key="i"
          >
            <div class="card mb-2">
              <div class="card-header p-3 pt-2">
                <div
                  class="icon icon-md icon-shape bg-gradient-danger shadow-secondary shadow text-center border-radius-xl mt-n4 position-absolute"
                >
                  <i class="material-icons opacity-10">payment</i>
                </div>
                <div class="text-end pt-1">
                  <p class="text-sm mb-0">{{ value.label }}</p>
                  <h4 class="mb-0">
                    {{ value.value
                    }}{{ k === "totalCancelledAmount" ? " грн" : "" }}
                  </h4>
                </div>
              </div>
              <hr class="dark horizontal my-0" />
              <div class="card-footer p-3">
                <p class="text-xs">
                  {{ value.tooltip }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-12"> -->
      <div class="px-0 pb-0 card-body">
        <div
          class="wrapper-page-analytics container-fluid py-4"
          v-if="!chartsDataLoaded.loadByStaff"
        >
          <Spinner :loaded="chartsDataLoaded.loadByStaff"></Spinner>
        </div>

        <div class="table-responsive">
          <vue-good-table
            mode="remote"
            :pagination-options="{
              enabled: true,
              perPage: serverParams.perPage,
              position: 'bottom',
              perPageDropdownEnabled: false,
              nextLabel: 'наст.',
              prevLabel: 'попер.',
              rowsPerPageLabel: 'записів на сторінці',
              ofLabel: 'із',
              pageLabel: 'сторінка',
              allLabel: 'всі',
            }"
            :totalRows="totalRecords"
            v-model:isLoading="isLoading"
            :columns="datatableColumns"
            :rows="rows"
            theme="default"
            styleClass="vgt-table bordered"
            responsive
            compactMode
            v-on:page-change="onTablePageChange"
          >
            <template #table-row="props">
              <div v-if="props.column.field === 'icon'">
                <span
                  v-if="props.row?._id"
                  class="material-icons align-middle mb-1 fs-6"
                  @click="showServiceOrder(props.row)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Переглянути бронювання"
                >
                  visibility
                </span>
              </div>

              <div v-if="props.column.field === 'sequenceId'">
                <div class="row">
                  <span class="text-xs">{{ props.row.sequenceId }}</span>
                </div>
              </div>

              <div v-if="props.column.field === 'createdAt'">
                <div class="row">
                  <span class="text-xs">{{
                    timestampToString(props.row.createdAt)
                  }}</span>
                </div>
              </div>

              <div v-if="props.column.field === 'serviceDateFrom'">
                <div class="row">
                  <span class="text-xs">{{
                    timestampToString(props.row.serviceDateFrom)
                  }}</span>
                </div>
              </div>

              <div v-if="props.column.field === 'serviceDateTo'">
                <div class="row">
                  <span class="text-xs">{{
                    serviceDateToToString(props.row.serviceDateTo)
                  }}</span>
                </div>
              </div>

              <div class="row" v-if="props.column.field === 'clientId'">
                <div
                  class="col-12"
                  v-for="(client, index) in props.row.clients"
                  :key="index"
                >
                  <span class="text-xs"
                    >{{ client.clientId?.firstName }}
                    {{ client.clientId?.lastName }}</span
                  ><br />
                  <span class="text-xs">{{
                    client.clientId?.phoneNumber
                  }}</span>
                </div>
              </div>

              <div v-if="props.column.field === 'services'">
                <div
                  class="text-xs"
                  v-for="(service, index) in props.row.services"
                  :key="index"
                >
                  {{ service.serviceId?.name }} ({{
                    displayDuration(service.serviceId?.duration)
                  }})
                </div>
                <div
                  class="my-2 text-xs font-weight-bold"
                  v-if="
                    props.row.customTotalDuration !== null &&
                    props.row.customTotalDuration !== undefined
                  "
                >
                  Тривалість:
                  {{ displayDuration(props.row.customTotalDuration) }}
                </div>
              </div>

              <div v-if="props.column.field === 'staffId'">
                <span class="my-2 text-xs">{{ props.row.staffId?.name }}</span>
              </div>

              <div v-if="props.column.fieldId === 'serviceDateTime'">
                {{ this.tableFieldServiceDateTime(props.row) }}
              </div>

              <div class="text-start" v-if="props.column.field === 'status'">
                <div class="badge badge-dot" v-if="props.row.status === 'new'">
                  <i class="bg-info"></i>
                  <span class="text-dark text-xs">Нова</span>
                </div>
                <div class="badge badge-dot" v-if="props.row.status === 'done'">
                  <i class="bg-success"></i>
                  <span
                    class="text-dark text-xs"
                    v-if="company.crmMode !== 'hotel'"
                    >Клієнт прийшов</span
                  >
                  <span
                    class="text-dark text-xs"
                    v-if="company.crmMode === 'hotel'"
                    >Клієнт відвідав</span
                  >
                </div>
                <div
                  class="badge badge-dot"
                  v-if="props.row.status === 'cancelled'"
                >
                  <i class="bg-danger"></i>
                  <span class="text-dark text-xs">Відмінено</span>
                </div>
                <div
                  class="badge bg-gradient-dark label-personal"
                  v-if="props.row.status === 'personal'"
                >
                  Особиста
                </div>
              </div>

              <div v-if="props.column.fieldId === 'fixedFinalPrice'">
                <span v-if="props.row.status === 'personal'"></span>
                <span v-else>{{
                  this.tableFieldServicesTotalPrice(props.row)
                }}</span>
              </div>

              <div v-if="props.column.field === 'paidAmount'">
                <div class="row">
                  {{ props.row.paidAmount }}
                </div>
              </div>

              <div v-if="props.column.field === 'leftToPay'">
                <div class="row">
                  {{ this.tableFieldServicesLeftToPay(props.row) }}
                </div>
              </div>

              <div v-if="props.column.field === 'payments'">
                <div
                  class="text-xs"
                  v-for="(payment, index) in props.row.payments"
                  :key="index"
                >
                  <span v-if="payment.paidAt">
                    {{ showDate(payment.paidAt) }}
                    ({{ showPaymentType(payment.operationType) }})
                  </span>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper-page-analytics >>> .flatpickr-input {
  color: #095bc6;
  background-color: #aecef7;
  border: 0;
  border-radius: 0.375rem;
  text-align: center;
  padding: 10px;
  font-weight: 700;
}

.bmn-staff-removed {
  text-decoration: line-through;
  opacity: 0.7;
}

.parent-container-no-data {
  height: 400px;
}

.vertical-center {
  font-size: x-large;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.infoBlock {
  color: #458466;
  background-color: #deede3;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 12px 12px 12px 12px;
}

.card-footer {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.card-header {
  padding-bottom: 4px !important;
}
.customHeight {
  line-height: 1px !important;
}
p {
  margin-bottom: 0.2rem;
}
</style>

<script>
import { DateTime } from "luxon";
import * as _ from "lodash";
import Spinner from "@/views/shared/Spinner.vue";

import setTooltip from "@/assets/js/tooltip.js";
import api from "@/services/api";
import constants from "@/constants";

import flatPickr from "vue-flatpickr-component";
import { Ukrainian } from "flatpickr/dist/l10n/uk";

import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";

import timeDisplay from "@/utils/timeDisplay";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  registerables,
} from "chart.js";
import allowedShowDevFeatures from "@/services/allowedShowDevFeatures";

ChartJS.register(ArcElement, Tooltip, Legend, ...registerables);

const paletteColors = [
  "#1A73E8",
  "#4CAF50",
  "#D6EAFC",
  "#FFFFDF",
  "#D3C4E3",
  "#B8DEDB",
  "#D3C4E3",
  "GoldenRod",
  "Lavender",
  "LavenderBlush",
  "LightBlue",
  "LightGoldenRodYellow",
  "NavajoWhite",
  "PaleGreen",
  "PaleTurquoise",
  "PapayaWhip",
  "PeachPuff",
  "Pink",
  "Plum",
  "RosyBrown",
  "Salmon",
  "SkyBlue",
  // "#F47A1F",
  // "#FDBB2F",
  // "#377B2B",
  // "#7AC142",
  // "#007CC3",
  // "#00529B",
  // "#6050DC",
  // "#D52DB7",
  // "#FF2E7E",
  // "#FF6B45",
  // "#FFAB05",
  // "#E6F69D",
  // "#AADEA7",
  // "#64C2A6",
  // "#2D87BB",
];

const getPeriod = (name) => {
  const period = {
    dateFrom: null,
    dateTo: null,
  };

  switch (name) {
    //   case "allTime":
    //     period.dateFrom = null;
    //     period.dateTo = null;
    //     break;

    case "currentWeek":
      period.dateFrom = DateTime.now().startOf("week").toISODate();
      period.dateTo = DateTime.now().endOf("week").toISODate();
      break;

    case "previousWeek":
      period.dateFrom = DateTime.now()
        .minus({ week: 1 })
        .startOf("week")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ week: 1 })
        .endOf("week")
        .toISODate();
      break;

    case "currentMonth":
      period.dateFrom = DateTime.now().startOf("month").toISODate();
      period.dateTo = DateTime.now().endOf("month").toISODate();
      break;

    case "nextMonth":
      period.dateFrom = DateTime.now()
        .plus({ month: 1 })
        .startOf("month")
        .toISODate();
      period.dateTo = DateTime.now()
        .plus({ month: 1 })
        .endOf("month")
        .toISODate();
      break;

    case "previousMonth":
      period.dateFrom = DateTime.now()
        .minus({ month: 1 })
        .startOf("month")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ month: 1 })
        .endOf("month")
        .toISODate();
      break;

    case "currentYear":
      period.dateFrom = DateTime.now().startOf("year").toISODate();
      period.dateTo = DateTime.now().endOf("year").toISODate();
      break;

    case "previousYear":
      period.dateFrom = DateTime.now()
        .minus({ year: 1 })
        .startOf("year")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ year: 1 })
        .endOf("year")
        .toISODate();
      break;

    case "monthToDate": // MTD
      period.dateFrom = DateTime.now().startOf("month").toISODate();
      period.dateTo = DateTime.now().toISODate();
      break;

    case "yearToDate": // YTD
      period.dateFrom = DateTime.now().startOf("year").toISODate();
      period.dateTo = DateTime.now().toISODate();
      break;
  }

  return period;
};

export default {
  name: "ecommerce-analytics-by-service-orders",

  components: {
    flatPickr,
    Spinner,
  },

  data() {
    return {
      dataLoaded: false,
      isLoading: true,
      showDevFeatures: false,

      company: {
        crmMode: "",
        localizationMode: "",
      },

      selectedDateRange: "currentMonth",
      listDateRanges: [
        {
          _id: "previousMonth",
          name: "Попередній місяць",
        },
        {
          _id: "currentMonth",
          name: "Поточний місяць",
        },
        {
          _id: "nextMonth",
          name: "Наступний місяць",
        },
        {
          _id: "currentWeek",
          name: "Поточний тиждень",
        },
        {
          _id: "previousWeek",
          name: "Попередній тиждень",
        },
        {
          _id: "currentYear",
          name: "Поточний рік",
        },
        {
          _id: "previousYear",
          name: "Попередній рік",
        },
        {
          _id: "monthToDate",
          name: "Поточний місяць (до сьогодні)",
        },
        {
          _id: "yearToDate",
          name: "Поточний рік (до сьогодні)",
        },
        {
          _id: "custom",
          name: "Довільні дати",
        },
      ],
      serverParams: {
        page: 1,
        perPage: constants.defaultDatatablePerPage,
      },
      datatableColumns: [
        {
          label: "",
          field: "icon",
          sortable: false,
          thClass: "thead-light text-center",
          tdClass: "text-xs font-weight-normal text-center",
          width: "50px",
        },
        {
          label: "#",
          field: "sequenceId",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "60px",
        },
        {
          label: "Дата створення",
          field: "createdAt",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "140px",
        },
        {
          label: "Дата броні",
          field: "serviceDate",
          fieldId: "serviceDateTime",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "140px",
        },
        {
          label: "Заїзд",
          field: "serviceDateFrom",
          fieldId: "serviceDateFrom",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "150px",
        },
        {
          label: "Виїзд",
          field: "serviceDateTo",
          fieldId: "serviceDateTo",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "140px",
        },
        {
          label: "Клієнт",
          field: "clientId",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "140px",
        },
        {
          label: "Послуга",
          field: "services",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal",
          width: "140px",
        },
        {
          label: this.getSingleLabelStaff(),
          field: "staffId",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal",
          width: "140px",
        },
        {
          label: "Статус",
          field: "status",
          sortable: false,
          type: "boolean",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start",
          tdClass: "text-xs font-weight-normal text-start",
        },
        {
          label: "Загальна вартість",
          field: this.tableFieldServicesTotalPrice,
          fieldId: "fixedTotalPrice",
          sortable: false,
          type: "string",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "До сплати",
          field: "leftToPay",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Сплачено",
          field: "paidAmount",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Повернуто",
          field: "refundedAmount",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Коментар",
          field: "comment",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "ID",
          field: "_id",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Дати оплат",
          field: "payments",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal",
          width: "140px",
        },
      ],

      customSelectedDateRange: null,
      calendarConfig: {
        mode: "range",
        dateFormat: "d-m-Y",
        locale: Ukrainian,
        inline: true,
        disableMobile: true,
        onChange: async (selectedDates) => {
          if (_.isArray(selectedDates) && selectedDates.length === 2) {
            this.selectedDateFrom = DateTime.fromJSDate(
              selectedDates[0],
            ).toISODate();
            this.selectedDateTo = DateTime.fromJSDate(
              selectedDates[1],
            ).toISODate();
            await this.fetchData();
          }
        },
      },

      totalPaid: 0,
      totalToPay: 0,
      // averagePaid: 0,
      metrics: {
        totalPaid: {
          label: "Дохід",
          tooltip: "Сума всіх оплат за винятком повернень",
          value: 0,
        },
        // averagePaid: {
        //   label: "Середній чек",
        //   tooltip:
        //     "Сума надходжень за всіма бронюваннями розділена на кількість бронювань які не в статусі 'Відмінено'",
        //   value: 0,
        // },
        totalToPay: {
          label: "Очікує на оплату",
          tooltip:
            "Гроші, які ще неоплачені. Броні в статусі ʼВідміненоʼ не враховуються.",
          value: 0,
        },
      },
      metricsCancelled: {
        totalCancelledAmount: {
          label: "На суму",
          tooltip: "Сума вартостей всіх відмінених бронювань за обраний період",
          value: 0,
        },
        totalCancelled: {
          label: "Кількість",
          tooltip: "Кількість відмінених бронювань за обраний період",
          value: 0,
        },
      },
      rows: [],
      totalRecords: 0,

      selectedClientsPeriodFrom: null,
      selectedClientsPeriodTo: null,
      clientsPeriod: null,
      calendarConfigClients: {
        defaultDate: Date.now(),
        plugins: [
          new MonthSelectPlugin({
            shorthand: false, //defaults to false
            dateFormat: "🗓 F Y", //defaults to "F Y"
            altFormat: "🗓 F Y", //defaults to "F Y"
            theme: "light", // defaults to "light"
          }),
        ],
        locale: Ukrainian,
        inline: false,
        disableMobile: true,
        onChange: async (selectedDates) => {
          if (!_.isArray(selectedDates) || selectedDates.length !== 1) {
            selectedDates = [new Date()];
          }

          this.selectedClientsPeriodFrom = DateTime.fromJSDate(selectedDates[0])
            .startOf("month")
            .toISODate();
          this.selectedClientsPeriodTo = DateTime.fromJSDate(selectedDates[0])
            .endOf("month")
            .toISODate();

          const todayDate = DateTime.now().toISODate();
          if (
            DateTime.fromISO(todayDate).startOf("month").toISODate() ===
            this.selectedClientsPeriodFrom
          ) {
            // exception: current month -> to current date
            this.selectedClientsPeriodTo = todayDate;
          }
        },
        maxDate: Date.now(),
      },

      selectedPositionId: null,
      listPositions: [
        {
          _id: null,
          name: "Всі категорії",
          positionId: null,
        },
      ],

      selectedStaffId: null,
      listStaff: [
        {
          _id: null,
          name: "Всі",
          positionId: null,
        },
      ],

      selectedDateFrom: null,
      selectedDateTo: null,
      chartsDataLoaded: {
        loadByStaff: false,
      },
      charts: {
        loadByStaff: {
          data: {
            labels: [],
            datasets: [
              {
                backgroundColor: paletteColors,
                data: [],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                },
              },
              y: {
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 10,
                },
              },
            },
          },
        },
      },
    };
  },

  async created() {
    this.company = await api.company.show();

    this.listStaff[0].name = this.getLabelStaff();
    this.listPositions[0].name = this.getLabelPosition();

    // TODO: set includeRemoved to 1 to include removed staffs
    const staff = await api.staff.list({ includeRemoved: 0 });
    const positions = await api.positions.list();

    this.listStaff = [...this.listStaff, ...staff];
    this.listPositions = [...this.listPositions, ...positions];

    const defaultPeriod = getPeriod("currentMonth");
    this.selectedDateFrom = defaultPeriod.dateFrom;
    this.selectedDateTo = defaultPeriod.dateTo;

    if (this.company.crmMode === "hotel") {
      const serviceDateColumn = this.datatableColumns.find(
        (column) => column.field === "serviceDate",
      );
      if (serviceDateColumn) {
        serviceDateColumn.hidden = true;
      }

      const servicesColumn = this.datatableColumns.find(
        (column) => column.field === "services",
      );

      if (servicesColumn) {
        servicesColumn.hidden = true;
      }

      // this.metrics.averagePaid.tooltip =
      //   "Сума надходжень за всіма бронюваннями розділена на кількість бронювань які не в статусі 'Відмінено'";
      this.metrics["totalToPay"].tooltip =
        "Залишок неотриманих коштів за відпочинок в обраному періоді. Враховуються всі бронювання окрім броней зі статусом 'Відмінено'";
    } else {
      const serviceDateFromColumn = this.datatableColumns.find(
        (column) => column.field === "serviceDateFrom",
      );
      if (serviceDateFromColumn) {
        serviceDateFromColumn.hidden = true;
      }

      const serviceDateToColumn = this.datatableColumns.find(
        (column) => column.field === "serviceDateTo",
      );
      if (serviceDateToColumn) {
        serviceDateToColumn.hidden = true;
      }

      // this.metrics.averagePaid.tooltip =
      //   "Сума надходжень розділена на кількість записів";
      this.metrics["totalToPay"].tooltip =
        "Залишок неотриманих коштів за послуги, виконані в обраному періоді";
    }

    await this.fetchData();
  },

  async mounted() {
    this.showDevFeatures = allowedShowDevFeatures();
    setTimeout(setTooltip, 200);
    await this.calendarConfigClients.onChange();
    this.dataLoaded = true;
    this.isLoading = false;
  },

  methods: {
    getSingleLabelStaff() {
      const localizationMode = this.company?.localizationMode;

      switch (true) {
        case localizationMode === "spa":
          return "Ресурс";
        case localizationMode === "hotel":
          return "Номер";
        default:
          return "Майстер";
      }
    },

    getLabelStaff() {
      const localizationMode = this.company?.localizationMode;

      switch (true) {
        case localizationMode === "spa":
          return "Всі ресурси";
        case localizationMode === "hotel":
          return "Всі номери";
        default:
          return "Всі майстри";
      }
    },

    getLabelPosition() {
      const localizationMode = this.company?.localizationMode;

      switch (true) {
        case localizationMode === "spa":
        case localizationMode === "hotel":
          return "Всі категорії";
        default:
          return "Всі категорії";
      }
    },

    async fetchData() {
      this.chartsDataLoaded.loadByStaff = false;
      this.isLoading = true;

      const payload = {
        scope: "general",
        staffId: this.selectedStaffId,
        positionId: this.selectedPositionId,
        limit: this.serverParams.perPage,
        offset: (this.serverParams.page - 1) * this.serverParams.perPage,
      };

      if (this.selectedDateFrom) {
        payload.dateFrom = this.selectedDateFrom;
      }

      if (this.selectedDateTo) {
        payload.dateTo = this.selectedDateTo;
      }

      const result = await api.analytics.getServiceOrders(payload);

      this.metrics.totalPaid.value = result.totalPaid;
      this.metrics.totalToPay.value = result.totalToPay;
      // this.metrics.averagePaid.value = result.averagePaid;
      this.metricsCancelled.totalCancelledAmount.value =
        result.totalCancelledAmount;
      this.metricsCancelled.totalCancelled.value = result.totalCancelled;

      this.rows = result.rows;
      this.totalRecords = result.totalRecords;

      this.chartsDataLoaded.loadByStaff = true;
      this.isLoading = false;
    },

    async filterByDateRange(dateRange) {
      this.selectedDateRange = dateRange;

      const rangeValue = getPeriod(dateRange);
      this.selectedDateFrom = rangeValue.dateFrom;
      this.selectedDateTo = rangeValue.dateTo;

      if (dateRange !== "custom") {
        this.$refs.datePickerWrap.fp.clear();
        await this.fetchData();
      }
    },

    displaySelectedDateRange() {
      const _dateRange = this.listDateRanges.find((element) => {
        return element._id === this.selectedDateRange;
      });
      return _dateRange.name;
    },
    async filterByPositionId(positionId) {
      this.selectedPositionId = positionId;
      await this.fetchData();
      // await this.fetchDataClients();
    },
    displaySelectedPosition() {
      const _position = this.listPositions.find((element) => {
        return element._id === this.selectedPositionId;
      });
      return _position.name;
    },
    async filterByStaffId(staffId) {
      this.selectedStaffId = staffId;
      await this.fetchData();
      // await this.fetchDataClients();
    },
    displaySelectedStaff() {
      const _staff = this.listStaff.find((element) => {
        return element._id === this.selectedStaffId;
      });

      const suffix = _staff._id && !_staff.isActive ? "❌" : "";

      if (_staff.positionId) {
        return `${_staff.name} (${_staff.positionId.name}) ${suffix}`;
      }
      return `${_staff.name} ${suffix}`;
    },

    timestampToString(input) {
      if (!input) {
        return "-";
      }
      const datetime = DateTime.fromISO(input);
      return datetime
        .setLocale("uk-UA")
        .toLocaleString({ month: "short", day: "numeric", year: "numeric" });
    },
    serviceDateToToString(input) {
      if (!input) {
        return "-";
      }
      const datetime = DateTime.fromISO(input).plus({
        days: 1,
      });
      return datetime
        .setLocale("uk-UA")
        .toLocaleString({ month: "short", day: "numeric", year: "numeric" });
    },
    displayDuration(minutes) {
      return timeDisplay.minutesToHumanFormat(minutes);
    },
    tableFieldServicesTotalPrice(row) {
      // if (row.fixedFinalPrice !== row.finalPrice) {
      //   return `${row.finalPrice} -> ${row.fixedFinalPrice}`;
      // }

      return row.fixedFinalPrice;
    },
    tableFieldServicesLeftToPay(row) {
      const sumToPay =
        (row.servicesCost || 0) +
        (row.additionalServicesCost || 0) -
        (row.discountAmount || 0);
      const alreadyPaid = row.paidAmount - row.refundedAmount;

      // const sumToPay = row.fixedFinalPrice - row.discountAmount;
      // const alreadyPaid = row.paidAmount - row.refundedAmount;

      return sumToPay - alreadyPaid;
    },
    tableFieldServiceDateTime(row) {
      if (row.serviceDateFrom && row.serviceDateTo) {
        const dateTo = DateTime.fromISO(row.serviceDateTo)
          .plus({ days: 1 })
          .toISODate();
        return `${this.timestampToString(
          row.serviceDateFrom,
        )} - ${this.timestampToString(dateTo)}`;
      }
      return `${this.timestampToString(row.serviceDate)} ${row.serviceTime}`;
    },
    showDate(isoDate) {
      if (!isoDate) {
        return "";
      }
      return DateTime.fromISO(isoDate)
        .setLocale("uk-UA")
        .toFormat("dd.MM.yyyy");
    },
    showPaymentType(operationType) {
      if (operationType === "cash") {
        return "готівка";
      }
      if (operationType === "card-transfer") {
        return "картка";
      }
      if (operationType === "monobank-acquiring") {
        return "monopay";
      }
      if (operationType === "liqpay-acquiring") {
        return "liqpay";
      }
    },
    async onTablePageChange(params) {
      this.serverParams.page = params.currentPage;
      await this.fetchData();
    },
    showServiceOrder(order) {
      const orderId = order?._id;
      if (!orderId) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Цього бронювання не існує",
          type: "error",
        });
        return;
      }

      open(`/service-orders/${orderId}`, "_self");
    },
  },

  computed: {
    computedSelectedDateRange() {
      let from = "(Дата з)";
      let to = "(Дата до)";
      if (this.selectedDateFrom) {
        from = DateTime.fromISO(this.selectedDateFrom)
          .setLocale("uk-UA")
          .toFormat("dd.MM.yyyy");
      }
      if (this.selectedDateTo) {
        to = DateTime.fromISO(this.selectedDateTo)
          .setLocale("uk-UA")
          .toFormat("dd.MM.yyyy");
      }
      return `${from} - ${to}`;
    },
  },
};
</script>
