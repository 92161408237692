<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mt-3">
      <div class="col-lg-12 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="channgelManagerAllignment">
                <div class="element">
                  <h3>Менеджер каналів</h3>
                </div>
                <div class="form-check mt-2 form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    @change="save"
                    v-model="company.isChannelManagerEnabled"
                    :disabled="!showDevFeatures"
                  />
                </div>
              </div>

              <div class="">
                Якщо ви використовуєте декілька каналів продажів, то за
                допомогою Менеджера каналів ви можете підключити синхронізацію
                бронювань між bookmenow і більшістю Online Travel Agencies, як
                то booking.com / airbnb / тощо.<br />
                Фінкціонал Менеджера каналів тарифікується окремо за ціною
                вказаною
                <a
                  href="https://bookmenow.pro/pricing-hotels"
                  target="_blank"
                  style="text-decoration: underline"
                  >тут</a
                >
                <br />
                <br />

                <!-- <h6 class="">Як підключити bookmenow до booking.com</h6> -->
                <div>
                  <div class="text-bold">Як підключити bookmenow до airbnb</div>
                  <p>
                    <a
                      href="https://bookmenow.pro/kb/integration-airbnb"
                      target="_blank"
                      >https://bookmenow.pro/kb/integration-airbnb</a
                    >
                  </p>
                </div>
                <div>
                  <div class="text-bold">
                    Як підключити bookmenow до booking.com
                  </div>
                  <p>
                    <a
                      href="https://bookmenow.pro/kb/integration-booking"
                      target="_blank"
                      >https://bookmenow.pro/kb/integration-booking</a
                    >
                  </p>
                </div>
              </div>
              <hr />
              <div class="row" v-if="!company.isChannelManagerEnabled">
                <div class="col-lg-12 my-auto mt-3">
                  <h5>Підключити Менеджер каналів</h5>
                  <p class="text-muted text-sm mt-3">
                    Для підключення функціоналу - заповніть, будь ласка, форму:
                  </p>
                  <div class="">
                    <strong>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfDVgZJCftP8xSXcvFF076PIER7ZMVXmWrb0v-8-DHIkIKa2w/viewform"
                        target="_blank"
                        class="btn btn-md bg-gradient-primary mb-0"
                      >
                        Заповнити форму
                      </a>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import Spinner from "@/views/shared/Spinner.vue";
import allowedShowDevFeatures from "@/services/allowedShowDevFeatures";
// import SmallLoader from "@/views/shared/SmallLoader.vue";

export default {
  name: "channel-manager",

  async mounted() {
    this.company = await api.company.show();
    this.showDevFeatures = allowedShowDevFeatures();

    this.dataLoaded = true;
  },

  data() {
    return {
      showDevFeatures: false,
      dataLoaded: false,
      isLoading: false,
      company: {
        crmMode: "",
        isChannelManagerEnabled: false,
      },
    };
  },

  components: {
    // SmallLoader,
    Spinner,
  },

  methods: {
    async save() {
      if (!this.dataLoaded || !this.showDevFeatures) {
        return;
      }

      this.isLoading = true;

      try {
        await api.company.update({
          isChannelManagerEnabled: this.company.isChannelManagerEnabled,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.container-fluid {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.element {
  margin-top: 8px;
  margin-right: 20px;
}
.channgelManagerAllignment {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-start;
  padding-right: 16px;
}
</style>
