<template>
  <div class="container-fluid">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div v-if="dataLoaded" class="container-fluid mt-3">
    <div class="row">
      <h4>Налаштування відгуків</h4>
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">
            <span class="text-sm" v-if="company.crmMode === 'hotel'">
              Увімкнувши функцію збору відгуків ми автоматично відправимо вашим
              Гостям СМС запит на відгук через 1 годину після виселення.<br />
              Отримані відгуки мають статус "Опублікований" за замовчуванням і
              лише ви можете сховати відгук змінивши йому статус на
              "Неопубліковано".<br />
            </span>
            <span class="text-sm" v-if="company.crmMode !== 'hotel'">
              Увімкнувши функцію збору відгуків ми автоматично відправимо вашим
              Клієнтам СМС запит на відгук через 1 годину після завершення
              послуги.<br />
              Отримані відгуки мають статус "Опублікований" за замовчуванням і
              лише ви можете сховати відгук змінивши йому статус на
              "Неопубліковано".<br />
              Після публікації відгук зʼявиться на вашій сторінці онлайн
              бронювання.
            </span>
            <div class="form-check form-switch mt-2">
              <input
                class="form-check-input customCheckbox"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="allowFeedbacks"
                @change="toggleFeedbackSwitcher"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Отримувати відгуки</label
              >
            </div>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-body">
            <div class="card-body p-1">
              <div class="row">
                <div class="col-lg-6">
                  <h5>Шаблон SMS запиту на відгук</h5>
                  <div class="row infoBlock">
                    <div class="d-flex align-items-center gap-2">
                      <span class="material-symbols-outlined"> info </span>

                      <label
                        class="text-xs"
                        v-if="company.crmMode === 'hotel'"
                        style="color: #458466"
                      >
                        Запит на відгук відправляється в визначений в
                        Налаштуваннях час.<br />
                        Приклад:<br />
                        В налаштуваннях відгуків встановлена відправка через 1г
                        після виселення<br />
                        В налаштуваннях номеру, в якому мешкає гість,
                        встановлено час виселення 12:00<br />
                        Запит на відгук буде відправлено в день виїзду о 13:00.
                      </label>
                      <label class="text-xs" v-if="company.crmMode !== 'hotel'">
                        Запит на відгук відправляється в визначений в
                        Налаштуваннях час.<br />
                        Приклад:<br />
                        В налаштуваннях відгуків встановлена відправка через 1г
                        після завершення запису<br />
                        Запис закінчується о 12:--<br />
                        Запит на відгук буде відправлено о 13:00.
                      </label>
                    </div>
                  </div>
                  <div class="input-group input-group-outline">
                    <!-- TODO: Fix placeholder text -->
                    <textarea
                      class="form-control"
                      placeholder="Шановний, {имя_клиента}, дякуємо, що обрали нас! Будь ласка, залиште відгук за посиланням: {посилання_на_відгук}."
                      rows="4"
                      type="text"
                      v-model="smsServiceOrderFeedbackTemplate"
                      style="height: 100px"
                    />
                  </div>
                  <b class="text-xs">Змінні:</b>
                  <p class="text-xs">
                    {имя_клиента}, {посилання_на_відгук}, {перенос_строки}
                  </p>
                </div>
                <div class="col-lg-6">
                  <h5>Час відправки</h5>
                  <label v-if="company.crmMode === 'hotel'"
                    >Відправити після виcелення через:</label
                  >
                  <label v-if="company.crmMode !== 'hotel'"
                    >Відправити після запису через:</label
                  >
                  <div class="row">
                    <div class="col-lg-6">
                      <SelectApp
                        class="form-control"
                        v-model="selectedFeedbackSentOption"
                        :settings="{
                          allowEmpty: false,
                          options: feedbackSentOptions,
                          trackBy: 'value',
                          label: 'label',
                        }"
                        @select="setSelectedFeedbackSentAfterMinutes"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-outline-primary"
                @click="saveFeedbackSettings"
              >
                Зберегти шаблон і час відправки
              </button>
            </div>
          </div>
        </div>
        <!-- Button trigger modal -->
        <button
          type="button"
          class="btn bg-gradient-primary"
          @click="showModal = true"
        >
          + Параметр
        </button>

        <!-- Modal -->
        <ReviewAttributeModel
          v-if="showModal"
          :attribute="selectedAttribute"
          @submit="
            loadItems();
            closeModal();
          "
          @close="closeModal"
        />
        <div class="card mb-3">
          <div class="card-body">
            <div class="card-body p-1">
              <div class="row">
                <div class="col-lg-9">
                  <label class="text-md text-bold">Параметри оцінки</label>
                  <vue-good-table
                    v-if="dataLoaded"
                    :columns="columns"
                    :rows="tableData.rows"
                    :sort-options="{ enabled: false }"
                    theme="default"
                    styleClass="vgt-table bordered"
                    responsive
                    compactMode
                  >
                    <template #table-row="props">
                      <div v-if="props.column.field === 'label'">
                        <div class="d-flex flex-column justify-content-center">
                          <p class="text-xs font-weight-normal mb-0">
                            {{ props.row.label }}
                          </p>
                        </div>
                      </div>
                      <div v-else-if="props.column.field === 'enabled'">
                        <div
                          class="d-flex flex-column justify-content-center badge badge-dot"
                        >
                          <div v-if="props.row.enabled">
                            <i class="bg-success"></i>
                            <span class="text-dark text-xs">Увімкнено</span>
                          </div>
                          <div v-if="!props.row.enabled">
                            <i class="bg-danger"></i>
                            <span class="text-dark text-xs">Вимкнено</span>
                          </div>
                        </div>
                      </div>
                      <div v-if="props.column.field === 'actions'">
                        <div class="d-flex flex-column justify-content-center">
                          <span
                            @click="updateAttribute(props.row)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Редагувати аттрибут"
                          >
                            <i
                              class="material-icons text-lg me-2 cursor-pointer"
                              >edit</i
                            >
                          </span>
                        </div>
                      </div>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import { useTable } from "@/composables/good-table";

import ReviewAttributeModel from "./ReviewAttributeModel.vue";
import Spinner from "@/views/shared/Spinner.vue";
import SelectApp from "@/components/common/SelectApp.vue";
import timeDisplay from "@/utils/timeDisplay";
import range from "lodash/range";

export default {
  setup() {
    const { loadItems, tableData, isLoading } = useTable(
      api.feedbackAttributes.list,
    );

    return {
      loadItems,
      isLoading,
      tableData,
    };
  },
  components: { ReviewAttributeModel, Spinner, SelectApp },
  name: "review-settings",
  data() {
    return {
      showModal: false,
      selectedAttribute: null,
      dataLoaded: false,
      company: {
        crmMode: "",
      },
      allowFeedbacks: false,
      columns: [
        {
          label: "Параметр оцінки",
          field: "label",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal ",
        },
        {
          label: "Статус",
          field: "enabled",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Дія",
          field: "actions",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
      ],

      feedbackSentOptions: [],
      selectedFeedbackSentOption: {},

      smsServiceOrderFeedbackTemplate: "",
      smsServiceOrderFeedbackSendAfterMinutes: 60,
    };
  },
  async mounted() {
    this.company = await api.company.show();
    this.allowFeedbacks = this.company.allowFeedbacks || false;
    this.smsServiceOrderFeedbackTemplate =
      this.company.smsServiceOrderFeedbackTemplate || "";
    this.smsServiceOrderFeedbackSendAfterMinutes =
      this.company.smsServiceOrderFeedbackSendAfterMinutes || 60;

    this.feedbackSentOptions = range(1, 49).map((value) => {
      return {
        value: value * 60,
        label: timeDisplay.minutesToHumanFormat(value * 60),
      };
    });

    this.selectedFeedbackSentOption = this.feedbackSentOptions.find(
      (option) => option.value === this.smsServiceOrderFeedbackSendAfterMinutes,
    );

    setTimeout(async () => {
      await this.loadItems();
      this.dataLoaded = true;
    }, 500);
  },
  methods: {
    updateAttribute(item) {
      this.selectedAttribute = item;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedAttribute = null;
    },
    async toggleFeedbackSwitcher() {
      try {
        await api.company.update({
          allowFeedbacks: this.allowFeedbacks,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },
    setSelectedFeedbackSentAfterMinutes(input) {
      this.smsServiceOrderFeedbackSendAfterMinutes = input.value;
    },
    async saveFeedbackSettings() {
      try {
        await api.company.update({
          smsServiceOrderFeedbackTemplate: this.smsServiceOrderFeedbackTemplate,
          smsServiceOrderFeedbackSendAfterMinutes:
            this.smsServiceOrderFeedbackSendAfterMinutes,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.customCheckbox {
  margin-top: 0.4rem;
}
.customCheck {
  padding-left: 0;
}
.disableCopyElement {
  -webkit-touch-callout: none; /* Отключение контекстного меню iOS */
  -webkit-user-select: none; /* Отключение выбора текста в Safari */
  -khtml-user-select: none; /* Отключение выбора текста в Konqueror HTML */
  -moz-user-select: none; /* Отключение выбора текста в Firefox */
  -ms-user-select: none; /* Отключение выбора текста в Internet Explorer/Edge */
  user-select: none; /* Отключение выбора текста в большинстве современных браузеров */
}

table.vgt-table td {
  vertical-align: middle;
}

.img-thumbnail {
  padding: 0;
  border: none;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

tr {
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background: #fbfbfb;
  }
}

.grab-box {
  cursor: grab;
}

.ghost {
  opacity: 0.7;
  background: #d7d6d6;
}
.infoBlock {
  color: #458466;
  background-color: #deede3;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 12px 12px 12px 12px;
}
label,
.form-label {
  margin-bottom: 0px !important;
}
.container-fluid {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
</style>
