const allowedShowDevFeatures = () => {
  const showDevFeatures = !!localStorage.getItem("showDevFeatures");
  if (!showDevFeatures) {
    return false;
  }

  const hideDevFeaturesAnyway = !!localStorage.getItem("hideDevFeaturesAnyway");
  if (hideDevFeaturesAnyway) {
    return false;
  }

  return true;
};

export default allowedShowDevFeatures;
