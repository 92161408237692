<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="card mt-3">
            <div class="badge bg-gradient-warning">Super admin</div>
            <!-- liqpay acquiring -->
            <div class="card mt-4 pb-3" id="additional-settings">
              <div class="card-body">
                <h6>Прийом оплати з LiqPay</h6>
                <div class="row mt-3">
                  <div class="col-lg-8">
                    <span class="text-sm font-weight-normal">
                      <strong>Увімкнути LiqPay еквайринг.</strong>
                    </span>
                  </div>
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="col-6 hours">
                        <div class="input-group input-group-outline">
                          <div class="col-2 form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="branchTemplate.paymentLiqPayEnabled"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-lg-8">
                    <span class="text-sm font-weight-normal">
                      <strong>LiqPay публічний токен.</strong> За допомогою
                      LiqPay acquiring ви можете приймати оплату або переодплату
                      за ваші послуги. Для підключення bookmenow до вашого
                      профілю в LiqPay acquiring, будь ласка, вкажіть ваш
                      публічний та приватний ключі для інтернет-еквайрингу з
                      особистого кабінету в LiqPay.</span
                    >
                  </div>
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="col-12 hours">
                        <div
                          class="input-group input-group-outline"
                          :class="{
                            'is-filled': branchTemplate.paymentLiqPayPublicKey,
                            'is-invalid': !isValidLiqPayPublicKey(),
                          }"
                        >
                          <label class="form-label">Публічний Ключ</label>
                          <input
                            type="password"
                            min="1"
                            class="form-control"
                            :disabled="!branchTemplate.paymentLiqPayEnabled"
                            v-model="branchTemplate.paymentLiqPayPublicKey"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-12 hours">
                        <div
                          class="input-group input-group-outline"
                          :class="{
                            'is-filled': branchTemplate.paymentLiqPayPrivateKey,
                            'is-invalid': !isValidLiqPayPrivateKey(),
                          }"
                        >
                          <label class="form-label">Приватний Ключ</label>
                          <input
                            type="password"
                            min="1"
                            class="form-control"
                            :disabled="!branchTemplate.paymentLiqPayEnabled"
                            v-model="branchTemplate.paymentLiqPayPrivateKey"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- liqpay acquiring finish -->
          </div>
        </div>
      </div>
      <div class="fixed-bottom block">
        <div class="row">
          <div class="col-10"></div>
          <div class="col-lg-2">
            <div class="mx-4">
              <button
                type="button"
                class="block btn bg-gradient-primary"
                :disabled="!presaveValidation()"
                @click="save"
              >
                Зберегти
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>

<script>
import api from "@/services/api";
import Spinner from "@/views/shared/Spinner.vue";
import allowedShowDevFeatures from "@/services/allowedShowDevFeatures";

export default {
  name: "integrations-liqpay",

  async created() {
    this.company = await api.company.show();
    this.showDevFeatures = allowedShowDevFeatures();

    this.branchTemplate = (await api.branches.list())[0];
  },

  mounted() {
    this.dataLoaded = true;
  },

  data() {
    return {
      showDevFeatures: false,
      dataLoaded: false,

      company: {
        crmMode: "",
      },

      branchTemplate: {
        paymentLiqPayEnabled: false,
        paymentLiqPayPublicKey: null,
        paymentLiqPayPrivateKey: null,
      },
    };
  },

  components: {
    Spinner,
  },
  methods: {
    isValidLiqPayPublicKey() {
      if (
        this.branchTemplate.paymentLiqPayEnabled &&
        !this.branchTemplate.paymentLiqPayPublicKey
      ) {
        return false;
      }

      return true;
    },

    isValidLiqPayPrivateKey() {
      if (
        this.branchTemplate.paymentLiqPayEnabled &&
        !this.branchTemplate.paymentLiqPayPrivateKey
      ) {
        return false;
      }

      return true;
    },

    presaveValidation() {
      if (!this.isValidLiqPayPublicKey() || !this.isValidLiqPayPrivateKey()) {
        return false;
      }

      return true;
    },

    async save() {
      if (!this.presaveValidation()) {
        return;
      }

      let mbEnabled = this.branchTemplate.paymentMonobankEnabled;
      if (this.branchTemplate.paymentLiqPayEnabled) {
        mbEnabled = false;
      }

      try {
        await api.branches.updateOne(this.branchTemplate._id, {
          paymentLiqPayEnabled: this.branchTemplate.paymentLiqPayEnabled,
          paymentLiqPayPublicKey: this.branchTemplate.paymentLiqPayPublicKey,
          paymentLiqPayPrivateKey: this.branchTemplate.paymentLiqPayPrivateKey,
          paymentMonobankEnabled: mbEnabled,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },
  },
};
</script>
